import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  deselectMgmtUnit,
  toggleMUDetailSection,
  openMUList
} from "../actions/mgmt_unit_actions.js";
import { mobileNavChanged } from "../actions/mobile_nav_actions";
import {
  openLightbox,
  closeLightbox,
  setCurrentSlideIndex,
  resetCurrentSlideIndex
} from "../actions/lightbox_actions";
import MgmtUnitDetailContainer from "../components/MgmtUnitDetail/index.jsx";

const getSelectedUnitData = (unit, data) => {
  if (!unit || !data) return {};
  return data[unit];
};

const getResponseData = (unit, data) => {
  if (!unit || !data) return [];
  return data.filter(response => {
    return response.management_unit === unit || response.report_mu === unit;
  });
};

const mapStateToProps = state => {
  const {
    actionsCombined,
    browser,
    lightbox,
    livemapData,
    logActionResponses,
    logMgmtResponses,
    mgmtUnits,
    reports
  } = state;

  const { selectedUnit, detailSection, data, envPhotoData } = mgmtUnits;
  return {
    actionsCombined: getResponseData(selectedUnit, actionsCombined),
    reports: getResponseData(selectedUnit, reports.data),
    appHeight: browser.height,
    detailSection,
    envPhotoData: getResponseData(selectedUnit, envPhotoData),
    lightBoxOpened: lightbox.opened,
    lightBoxIndex: lightbox.index,
    livemapData,
    selectedReport: lightbox.selectedReport,
    logMgmt: getResponseData(selectedUnit, logMgmtResponses.data),
    logActions: getResponseData(selectedUnit, logActionResponses.data),
    selectedMgmtUnit: selectedUnit,
    unitData: getSelectedUnitData(selectedUnit, data),
    mgmtUnitCenter: mgmtUnits.center,
    mgmtUnitZoom: mgmtUnits.zoom
  };
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      deselectMgmtUnit,
      toggleMUDetailSection,
      mobileNavChanged,
      openLightbox,
      closeLightbox,
      openMUList,
      setCurrentSlideIndex,
      resetCurrentSlideIndex
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MgmtUnitDetailContainer);
