import { COMBINE_ACTION_LOGS_REPORTS } from '../constants/action_names';

// some parts of the app require having logActionResponses & actionReports combined with certain fields normalized
const combined = (logs, reports, reportType) => ({
  type: COMBINE_ACTION_LOGS_REPORTS,
  logs,
  reports,
  reportType
});

export default combined;
