import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  closeLightbox,
  setCurrentSlideIndex,
  resetCurrentSlideIndex
} from "../actions/lightbox_actions";
import Lightbox from "../components/LightBox.jsx";

const mapStateToProps = ({ lightbox, browser }, ownProps) => {
  const { photos, slides } = lightbox.data;
  return {
    photos,
    slides,
    about: lightbox.about,
    feedback: lightbox.feedback,
    lightBoxOpened: lightbox.opened,
    lightBoxIndex: lightbox.index,
    viewportWidth: browser.width,
    viewportHeight: browser.height,
    queryParams: ownProps.query
  };
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      closeLightbox,
      setCurrentSlideIndex,
      resetCurrentSlideIndex
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Lightbox);
