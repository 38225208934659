import * as actions from "../constants/action_names.js";

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actions.LOG_ACTIONS_DATA_REQUEST:
      return {
        ...state,
        fetchingLogActionsData: true,
        data: null
      };
    case actions.LOG_ACTIONS_DATA_RESPONSE:
      return {
        ...state,
        fetchingLogActionsData: false,
        data: action.json
      };
    case actions.LOG_ACTIONS_DATA_ERROR_RESPONSE:
      return {
        ...state,
        fetchingLogActionsData: false,
        data: null,
        error: action.error
      };
    default:
      //
  }

  return state;
};

export default reducer;
