import { createSelector } from "reselect";
import keys from "lodash.keys";
import pickBy from "lodash.pickby";
import {
  TOGGLE_BASEMAP_LAYER,
  MAP_LAYERS_TAB_OPEN
} from "../constants/action_names.js";
import { basemapLayers } from "../constants/config";

// NOTE: map zoom & center is kept in URL Hash rather than Redux Store / state.map

// create default reducer state from config.basemapURLs
let defaultState = {};
const layerIds = basemapLayers.map(layer => {
  return layer.id;
});

layerIds.forEach(id => {
  let bool = false;
  if (id === "aerialImagery2020") bool = true;
  defaultState[id] = bool;
});

defaultState.layersTabOpen = false;

// select function to get currently selected basemap layer
const mapSelector = state => state.map;
export const selectedLayer = createSelector(
  mapSelector,
  map =>
    keys(
      pickBy(map, layer => {
        return layer === true;
      })
    )[0]
);

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case TOGGLE_BASEMAP_LAYER: {
      // Turn off all basemap layers by default
      const basemaps = Object.fromEntries(basemapLayers.map(l => [l.id, false]));

      // Only turn on selected basemap
      basemaps[action.layer] = true;

      return { ...state, ...basemaps };
    }

    case MAP_LAYERS_TAB_OPEN:
      return {
        ...state,
        layersTabOpen: !state.layersTabOpen
      };
    default:
      //
  }

  return state;
}

export default reducer;
