import { applyMiddleware, createStore, compose } from "redux";
import { createResponsiveStoreEnhancer } from "redux-responsive";
import middleware from "./middleware";

import reducer from "./reducers/index.js";

export default function makeStore(initialState) {
  const store = createStore(
    reducer,
    initialState,
    compose(
      createResponsiveStoreEnhancer(500), // throttle time
      applyMiddleware(...middleware)
    )
  );

  if (module.hot) {
    // enable hot module replacement
    module.hot.accept("./reducers/index.js", () => {
      const nextReducer = require("./reducers/index.js");
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}
