import PropTypes from "prop-types";
import React, { Component } from "react";
import ImageGallery from "react-image-gallery"; // vendor component
import cx from "classnames";
import equal from "deep-equal";
import { formatImages, parseMarkDown } from "../constants/app.js";

class ImageCarousel extends Component {
  // NOTE: `photoData` is created from multiple arrays due to photos potentially being stored within recent actions, management logs, & envPhotoData
  // because photoData is passed from the MU Detail to the LightBox, the ImageCarousel component could also receive processed photo data
  // instead of raw photoData. See the render function for how this is handled.
  static propTypes = {
    photoData: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        featured_photo: PropTypes.bool,
        photo_description: PropTypes.string,
        management_unit: PropTypes.string
      })
    ),
    processedImageData: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string, // photo caption
        original: PropTypes.string, // URL
        originalClass: PropTypes.string,
        size: PropTypes.shape({
          height: PropTypes.number,
          width: PropTypes.number
        })
      })
    ),
    lightBoxOpened: PropTypes.bool,
    openLightbox: PropTypes.func,
    lightBoxIndex: PropTypes.number,
    detailSection: PropTypes.string,
    selectedMgmtUnit: PropTypes.string // the currently selected MU
  };

  constructor(props) {
    super(props);
    this.state = { images: [] };
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillMount() {
    // determine the array of images for react-image-gallery to consume
    this.setImages(this.props);
  }

  componentDidMount() {
    // set the index of the carousel from currently opened MU Detail section
    this.setLightBoxIndexFromSectionOpen(this.props.detailSection);
  }

  componentWillReceiveProps(nextProps) {
    const { selectedMgmtUnit, lightBoxIndex, detailSection } = nextProps;
    if (selectedMgmtUnit !== this.props.selectedMgmtUnit) {
      // if it's a different MU recreate the photos
      this.setImages(nextProps);
    }
    if (
      selectedMgmtUnit === this.props.selectedMgmtUnit &&
      lightBoxIndex !== this.props.lightBoxIndex
    ) {
      // keeps the MU photo carousel in sync with the LightBox photo carousel when LightBox is open
      this.imageGallery.slideToIndex(lightBoxIndex);
    }
    if (
      this.props.detailSection !== detailSection &&
      selectedMgmtUnit === this.props.selectedMgmtUnit
    ) {
      // if user toggled a different MU Detail section check for photos for that section & advance the carousel if one is found
      this.setLightBoxIndexFromSectionOpen(detailSection);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.imageGallery &&
      this.imageGallery.getCurrentIndex() !== this.props.lightBoxIndex
    ) {
      // make sure react-image-gallery receives the current lightBoxIndex value
      this.imageGallery.slideToIndex(this.props.lightBoxIndex);
    }
    if (
      this.state.images.length &&
      prevProps.selectedMgmtUnit !== this.props.selectedMgmtUnit
    ) {
      // new MU was selected, make sure to advance the carousel to current opened section
      this.setLightBoxIndexFromSectionOpen(this.props.detailSection);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.selectedMgmtUnit !== this.props.selectedMgmtUnit ||
      nextProps.detailSection !== this.props.detailSection ||
      nextProps.lightBoxIndex !== this.props.lightBoxIndex ||
      !equal(nextState.images.length, this.state.images.length)
    );
  }

  setImages(props) {
    const { photoData, processedImageData } = props;
    if (!processedImageData && photoData.length) {
      this.setState({
        images: formatImages(photoData, { width: 280, height: 280 })
      });
    } else if (processedImageData && processedImageData.length) {
      this.setState({ images: processedImageData });
    } else {
      // make sure to not keep a previously selected MU's photoData if the currently selected MU has no photos
      this.setState({ images: [] });
    }
  }

  setLightBoxIndexFromSectionOpen(detailSection) {
    const { onSlideChange } = this.props;
    const { images } = this.state;
    // find the first image that matches the current open MU Detail section
    const index = images.findIndex(image => {
      return image.section === detailSection;
    });
    if (index < 0) return; // didn't match because there are no images for the current section, ignore
    onSlideChange(index); // alias to lightboxActions.setCurrentSlideIndex()
  }

  setFeaturedPhoto() {
    // TO DO: issue #57: Use timestamp and featured photo flag for determining first photo shown in carousel
  }

  handleClick() {
    // opens lightbox when user clicks on an image in the MU Detail
    const { photoData, lightBoxOpened, openLightbox } = this.props;
    if (!photoData || !photoData.length) return;
    if (!lightBoxOpened) openLightbox({ photos: photoData });
  }

  // custom renderItem function passed to react-image-gallery
  // so we can show a loading GIF when images are being fetched
  // TO DO: get a better loading GIF or use spin.js...
  renderItem(item) {
    let container;
    let img;
    let loadingGIF;
    let errorMsg;

    function handleImageLoad(e) {
      container.classList.remove("loading");
      img.style.display = "block";
      loadingGIF.style.display = "none";
      errorMsg.style.display = "none";
    }

    function handleImageError(e) {
      img.style.display = "none";
      loadingGIF.style.display = "none";
      errorMsg.style.display = "block";
    }

    const imgGalleryClassNames = cx({
      "image-gallery-image": true,
      loading: true,
      "img-sm": item.size.width <= 300,
      "img-lg": item.size.width > 300
    });

    return (
      <div ref={i => (container = i)} className={imgGalleryClassNames}>
        <img
          ref={i => (img = i)}
          style={{ display: "none" }}
          src={item.original}
          alt={item.originalAlt}
          srcSet={item.srcSet}
          sizes={item.sizes}
          onLoad={handleImageLoad}
          onError={handleImageError}
        />
        <img alt="" ref={i => (loadingGIF = i)} className="loading" />
        <div
          ref={i => (errorMsg = i)}
          className="img-error-msg"
          style={{ display: "none" }}
        >
          <p>There was a problem loading the image :(</p>
        </div>
        {item.description && (
          <span
            className="image-gallery-description"
            dangerouslySetInnerHTML={{ __html: parseMarkDown(item.description) }}
          />
        )}
      </div>
    );
  }

  renderImageGallery() {
    const { lightBoxIndex, lightBoxOpened, onSlideChange } = this.props;
    const { images } = this.state;

    return (
      <ImageGallery
        ref={i => (this.imageGallery = i)}
        items={images}
        infinite={false}
        slideInterval={2000}
        showThumbnails={false}
        showBullets={lightBoxOpened && images.length > 1}
        startIndex={lightBoxIndex || 0}
        onClick={this.handleClick}
        onSlide={index => {
          if (index !== lightBoxIndex) onSlideChange(index);
        }}
        renderItem={this.renderItem}
      />
    );
  }

  renderNoPhotosAvailable() {
    return (
      <div className="no-photos-available">
        <p>
          <em>No photos currently available</em>
        </p>
      </div>
    );
  }

  render() {
    const { images } = this.state;

    return images.length
      ? this.renderImageGallery()
      : this.renderNoPhotosAvailable();
  }
}

export default ImageCarousel;
