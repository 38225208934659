import PropTypes from "prop-types";
import React, { Component } from "react";
import Collapse from "react-collapse";
import cx from "classnames";

import { parseMarkDown } from "../../constants/app.js";

const ActionItem = ({ actionItem, markdown, openLightbox }) => {
  let body;
  if (markdown && markdown.length) {
    body = (
      <div
        className="markdown-parsed"
        dangerouslySetInnerHTML={{ __html: markdown }}
      />
    );
  } else if (actionItem.report_name) {
    body = (
      <p
        className="action-report-link"
        onClick={() => openLightbox({ slides: actionItem })}
      >
        <strong>Action Report:</strong> {actionItem.report_name}
      </p>
    );
  } else if (actionItem.photo_description) {
    let text = actionItem.text;
    if (!text) {
      text = actionItem.photo_description;
    }
    const markdown = parseMarkDown(text);

    body = (
      <p
        className="action-report-link"
        onClick={() => openLightbox({ photos: [actionItem] })}
      >
        <strong>Photo Update:</strong>{" "}
        <span
          className="action-report-link-description"
          dangerouslySetInnerHTML={{ __html: markdown }}
        />
      </p>
    );
  } else if (actionItem.text) {
    body = (
      <p>
        <strong>Action Update:</strong> {actionItem.text}
      </p>
    );
  }

  return (
    <li
      key={actionItem.report_id ? actionItem.report_id : actionItem.cartodb_id}
    >
      <p>{actionItem.date.format("MM/DD/YYYY")}</p>
      {body}
    </li>
  );
};

class ActionsInfo extends Component {
  static propTypes = {
    handleArrowClick: PropTypes.func.isRequired,
    detailSection: PropTypes.string.isRequired,
    sectionHeight: PropTypes.number.isRequired
  };

  constructor() {
    super();
    this.makeScrollable = this.makeScrollable.bind(this);
  }

  makeScrollable(height, opened) {
    const target = this.refs.actionItems;
    if (opened) {
      target.classList.add("scroll");
    } else {
      target.classList.remove("scroll");
    }
  }

  renderActionItems() {
    const { actionsCombined, openLightbox } = this.props;
    if (actionsCombined.length) {
      return actionsCombined.map(actionItem => (
        <ActionItem
          key={
            actionItem.report_id ? actionItem.report_id : actionItem.cartodb_id
          }
          actionItem={actionItem}
          markdown={
            actionItem.activity_description
              ? parseMarkDown(actionItem.activity_description)
              : ""
          }
          openLightbox={openLightbox}
        />
      ));
    } else {
      return (
        <li>
          <p>No recent actions logged.</p>
        </li>
      );
    }
  }

  render() {
    const { handleArrowClick, sectionHeight, detailSection, } = this.props;
    const actionsOpened = detailSection === "ACTIONS";

    const titleClassNames = cx({
      "title-and-arrow": true,
      transparent: true,
      opened: actionsOpened
    });

    return (
      <section className="actions-info transparent">
        <div
          className={titleClassNames}
          onClick={() => handleArrowClick("ACTIONS")}
        >
          <h2 className="title">Actions</h2>
        </div>
        <Collapse
          className="details transparent"
          isOpened={actionsOpened}
          fixedHeight={sectionHeight}
        >
          <div ref="actionItems" className="scroll">
            <ul>{this.renderActionItems()}</ul>
          </div>
        </Collapse>
      </section>
    );
  }
}

export default ActionsInfo;
