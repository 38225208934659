import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from "react-redux";
import { Router, Route, browserHistory } from "react-router";
import { syncHistoryWithStore } from "react-router-redux";

import makeStore from "./store.js";

import App from "./containers/AppConnected.jsx";
import Report from "./views/Report.jsx";
import "./scss/main.scss";

const store = makeStore();
const appHistory = syncHistoryWithStore(browserHistory, store);

ReactDOM.render(
  <Provider store={store}>
    <Router history={appHistory}>
      <Route path="/" component={App}>
        <Route path="reports/:id" component={Report} />
      </Route>

      <Route path="*" component={App} />
    </Router>
  </Provider>,
  document.getElementById("root")
);
