import PropTypes from "prop-types";
import React, { Component } from "react";
import fetch from "isomorphic-fetch";
import debounce from "lodash.debounce";
import { sql_endpoint, search_config } from "../constants/config";

/*
  http://andrewxhill.com/blog/2015/05/10/search-text-fast/

 */
class SearchInput extends Component {
  static propTypes = {
    onOptions: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.string,
    placeHolder: PropTypes.string,
    tabIndex: PropTypes.number
  };

  static defaultProps = {
    onFocus: d => d,
    onBlur: d => d,
    onChange: d => d,
    value: "",
    placeHolder: "Search",
    tabIndex: 1
  };

  constructor(props) {
    super(props);

    this.loadOptionsDebounced = debounce(this.loadOptionsDebounced, 250);
    this.state = { isLoading: false };
  }

  // NOTE: only updating when value is different
  shouldComponentUpdate(np) {
    return np.value !== this.props.value;
  }

  componentWillReceiveProps(np) {}

  componentDidUpdate(pp) {
    const { value } = this.props;
    if ((value === pp.value || value.length < 2) && value !== "") return;
    if (!this.props.onOptions) return;

    this.loadOptionsDebounced(this.props.value);
  }

  constructQuery(input) {
    return search_config.tables.map(t => {
      let searches = t.fields_to_search.map(s => {
        return `${s} ilike '%${input}%'`;
      });

      let additionalFilters =
        t.additional_filters && t.additional_filters.length
          ? ` AND ${t.additional_filters.join(" AND ")}`
          : "";

      return window.encodeURIComponent(
        `SELECT ${t.fields_to_return.join(",")} FROM ${
          t.name
        } WHERE ${searches.join(" OR ")}${additionalFilters}`
      );
    });
  }

  loadOptionsDebounced(input) {
    this.loadOptions(input);
  }

  // Inspired by https://github.com/JedWatson/react-select/blob/master/src/Async.js
  // TODO: cache?
  loadOptions(input) {
    let { onOptions } = this.props;

    if (!input || input.length < 2) {
      onOptions(null);
      return Promise.resolve({ options: [] });
    }

    const urls = this.constructQuery(input);

    const callback = (error, data) => {
      if (callback === this._callback) {
        this._callback = null;
        const options = (data && data.options) || [];
        onOptions(options);
        this.setState({
          isLoading: false
        });
      }
    };

    this._callback = callback;

    Promise.all(
      urls.map(url => {
        return fetch(`${sql_endpoint}q=${url}`).then(rsp => rsp.json());
      })
    )
      .then(json_files => {
        let options = {};
        json_files.forEach((file, i) => {
          let table = search_config.tables[i].name;
          options[table] = [];
          file.rows.forEach(r => {
            options[table].push(search_config.tables[i].nameAccessor(r));
          });
        });

        return { options };
      })
      .then(data => callback(null, data), error => callback(error));

    if (this._callback && !this.state.isLoading) {
      this.setState({
        isLoading: true
      });
    }
  }

  render() {
    const {
      value,
      placeHolder,
      tabIndex,
      onFocus,
      onBlur,
      onChange
    } = this.props;

    return (
      <input
        type="text"
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeHolder}
        tabIndex={tabIndex}
      />
    );
  }
}

export default SearchInput;
