import PropTypes from "prop-types";
import React, { Component } from "react";
import cx from "classnames";
import Collapse from "react-collapse";
import isEmpty from "lodash.isempty";

import TitleImgCarousel from "./TitleImgCarousel.jsx";
import EnvironmentInfo from "./EnvInfo.jsx";
import ManagementInfo from "./MgmtInfo.jsx";
import ActionsInfo from "./ActionsInfo.jsx";

/*
  NOTE:
  this file is connected to Redux via ../containers/MgmtUnitDetailConnected
  all Redux state & actions are passed down to child components
 */

class MgmtUnitDetailContainer extends Component {
  static propTypes = {
    appHeight: PropTypes.number.isRequired,
    displayMobile: PropTypes.bool.isRequired,
    selectedNav: PropTypes.bool.isRequired,
    mobileNavChanged: PropTypes.func.isRequired,
    selectedMgmtUnit: PropTypes.string,
    deselectMgmtUnit: PropTypes.func.isRequired,
    lightBoxOpened: PropTypes.bool.isRequired,
    openLightbox: PropTypes.func.isRequired,
    closeLightbox: PropTypes.func.isRequired,
    openMUList: PropTypes.func.isRequired,
    toggleMUDetailSection: PropTypes.func.isRequired,
    setCurrentSlideIndex: PropTypes.func.isRequired,
    resetCurrentSlideIndex: PropTypes.func.isRequired,
    unitData: PropTypes.shape({
      acres: PropTypes.number,
      elev_min: PropTypes.number,
      elev_max: PropTypes.number,
      habitat: PropTypes.string,
      om: PropTypes.number,
      pct_clay: PropTypes.number,
      pct_sand: PropTypes.number,
      pct_silt: PropTypes.number,
      texture: PropTypes.string,
      ins_m_mean: PropTypes.number,
      precip_avg: PropTypes.number,
      temp_avg: PropTypes.number,
      veg_class: PropTypes.string
    }),
    logMgmt: PropTypes.arrayOf(
      PropTypes.shape({
        cartodb_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        constraints: PropTypes.string,
        date_season_updated: PropTypes.string,
        egnyte_photo_public_token: PropTypes.string,
        goals: PropTypes.string,
        management_unit: PropTypes.string.required,
        photo_caption: PropTypes.string,
        plans: PropTypes.string,
        site_history: PropTypes.string,
        timestamp: PropTypes.string
      })
    ),
    logActions: PropTypes.arrayOf(
      PropTypes.shape({
        action_title: PropTypes.string,
        activity_description: PropTypes.string,
        cartodb_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        date: PropTypes.object,
        egnyte_photo_public_token_1: PropTypes.string,
        egnyte_photo_public_token_2: PropTypes.string,
        egnyte_photo_public_token_3: PropTypes.string,
        egnyte_photo_public_token_4: PropTypes.string,
        egnyte_photo_public_token_5: PropTypes.string,
        photo_caption_1: PropTypes.string,
        photo_caption_2: PropTypes.string,
        photo_caption_3: PropTypes.string,
        photo_caption_4: PropTypes.string,
        photo_caption_5: PropTypes.string,
        management_unit: PropTypes.string.required,
        timestamp: PropTypes.string,
        when: PropTypes.string
      })
    )
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpened: false, // is the entire MU Detail opened?
      imgCarouselOpened: true, // is the image carousel open?
      envOpened: true, // is the Environment section open?
      mgmtOpened: false, // is the Management section open?
      actionsOpened: false // is the Actions section open?
    };

    this.mgmtUnitDetail = null; // ref to MU Detail entire component
    this.titleImgCarousel = null; // ref to TitleImgCarousel component
    this.closeUnitDetail = this.closeUnitDetail.bind(this);
  }

  componentWillReceiveProps(nextProps, nextState) {
    const {
      selectedMgmtUnit,
      detailSection,
      unitData
    } = nextProps;

    // when an MU is selected, open the entire MU Detail component
    if (selectedMgmtUnit && !isEmpty(unitData)) {
      this.setState({
        isOpened: true,
        envOpened: detailSection === "ENV",
        mgmtOpened: detailSection === "MGMT",
        actionsOpened: detailSection === "ACTIONS",
        imgCarouselOpened: detailSection === "PHOTOS"
      });
    } else if (this.state.isOpened && !selectedMgmtUnit) {
      this.setState({
        isOpened: false
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.makeLinksExternal();
  }

  shouldComponentUpdate(nextProps, nextState) {
    // only need to update the MU Detail when these changes happen
    return (
      nextProps.selectedMgmtUnit !== this.props.selectedMgmtUnit ||
      nextState.isOpened !== this.state.isOpened ||
      nextProps.detailSection !== this.props.detailSection ||
      nextProps.mobileNavChanged !== this.props.mobileNavChanged ||
      nextProps.selectedNav !== this.props.selectedNav ||
      nextProps.lightBoxIndex !== this.props.lightBoxIndex ||
      nextProps.appHeight !== this.props.appHeight ||
      nextState.imgCarouselOpened !== this.state.imgCarouselOpened ||
      nextState.envOpened !== this.state.envOpened ||
      nextState.mgmtOpened !== this.state.mgmtOpened ||
      nextState.actionsOpened !== this.state.actionsOpened
    );
  }

  makeLinksExternal() {
    // if there are any hyperlinks in the sections make sure they open in a new tab
    const links = document.querySelectorAll(".markdown-parsed a");
    if (!links.length) return;
    // because `links` is a Node List, links.forEach() will not work in some browsers
    let i = 0;
    const l = links.length;
    for (i; i < l; i++) {
      if (links[i].hostname !== window.location.hostname) {
        links[i].target = "_blank";
      }
    }
  }

  closeUnitDetail(mobileNav) {
    this.props.deselectMgmtUnit();
    if (mobileNav === "mgmt_units") {
      this.props.openMUList();
      this.props.mobileNavChanged("mgmt_units");
    } else if (mobileNav === "map") {
      this.props.deselectMgmtUnit();
      this.props.mobileNavChanged("map");
    }
  }

  render() {
    const { isOpened, imgCarouselOpened } = this.state;
    const {
      reports,
      selectedMgmtUnit,
      unitData,
      logMgmt,
      logActions,
      appHeight,
      displayMobile,
      selectedNav,
      envPhotoData,
      openLightbox,
      lightBoxOpened,
      lightBoxIndex,
      livemapData,
      setCurrentSlideIndex,
      resetCurrentSlideIndex,
      detailSection,
      toggleMUDetailSection,
      actionsCombined,
      mgmtUnitCenter,
      mgmtUnitZoom
    } = this.props;

    // calc height of MU Detail & sections for react-collapse
    const componentHeight = displayMobile ? appHeight - 48 : appHeight - 75;
    const sectionHeaderHeight = 48;
    const carouselClosedHeight = 70;
    let sectionHeight =
      componentHeight - carouselClosedHeight - sectionHeaderHeight * 4;

    // class names helper
    const mgmtUnitDetailClassNames = cx({
      "mu-detail-container": true,
      open: this.state.isOpened,
      "ui-container": true,
      "bottom right": true
    });

    let style = {
      display: displayMobile && !selectedNav ? "none" : "block"
    };

    let unitLiveCams = [];
    if (livemapData.liveCams) {
      unitLiveCams = livemapData.liveCams.filter(
        v => v.mgmt_unit_name === selectedMgmtUnit
      );
    }

    let unitSoilStations = [];
    if (livemapData.soilStations) {
      unitSoilStations = livemapData.soilStations.filter(
        v => v.mgmt_unit_name === selectedMgmtUnit
      );
    }

    let unitWeatherStations = [];
    if (livemapData.weatherStations) {
      unitWeatherStations = livemapData.weatherStations.filter(
        v => v.mgmt_unit_name === selectedMgmtUnit
      );
    }

    return (
      <div
        ref={node => {
          this.mgmtUnitDetail = node;
        }}
        className={mgmtUnitDetailClassNames}
        style={style}
      >
        <Collapse isOpened={isOpened} fixedHeight={componentHeight}>
          <TitleImgCarousel
            name={selectedMgmtUnit}
            closeUnitDetail={this.closeUnitDetail}
            imgCarouselOpened={imgCarouselOpened}
            handleArrowClick={toggleMUDetailSection}
            liveCams={unitLiveCams}
            {...{
              selectedMgmtUnit,
              sectionHeight,
              lightBoxOpened,
              openLightbox,
              lightBoxIndex,
              detailSection,
              logMgmt,
              logActions,
              envPhotoData,
              setCurrentSlideIndex,
              resetCurrentSlideIndex,
              displayMobile,
              mgmtUnitCenter,
              mgmtUnitZoom
            }}
          />

          <EnvironmentInfo
            handleArrowClick={toggleMUDetailSection}
            soilStations={unitSoilStations}
            weatherStations={unitWeatherStations}
            {...{
              detailSection,
              sectionHeight,
              mgmtUnitCenter,
              mgmtUnitZoom,
              ...unitData
            }}
          />

          <ManagementInfo
            handleArrowClick={toggleMUDetailSection}
            {...{
              detailSection,
              reports,
              sectionHeight,
              logMgmt,
              openLightbox
            }}
          />

          <ActionsInfo
            handleArrowClick={toggleMUDetailSection}
            {...{ actionsCombined, detailSection, sectionHeight, openLightbox }}
          />
        </Collapse>
      </div>
    );
  }
}

export default MgmtUnitDetailContainer;
