import { combineReducers } from "redux";
import { createResponsiveStateReducer } from "redux-responsive";
import { routerReducer } from "react-router-redux";

// reducers
import combineActionLogsReports from "./combine_action_logs_reports";
import reportsReducer from "./reports_reducer";
import mapReducer, * as fromMapRedeucer from "./map_reducer.js";
import mgmtUnitsReducer from "./mgmt_units_reducer";
import logActionsReducer from "./log_actions_reducer";
import logMgmtResponsesReducer from "./log_mgmt_responses_reducer";
import lightboxReducer from "./lightbox_reducer";
import livemapDataReducer from "./livemap_data_reducer";
import mobileNavReducer from "./mobile_nav_reducer";

import { breakpoints } from "../scss/variables.json";

// custom breakpoints for redux-responsive store
const browser = createResponsiveStateReducer({
  extraSmall: breakpoints.width.xsmall,
  small: breakpoints.width.small,
  medium: breakpoints.width.medium,
  large: breakpoints.width.large
});

const rootReducer = combineReducers({
  actionsCombined: combineActionLogsReports,
  reports: reportsReducer,
  browser,
  mgmtUnits: mgmtUnitsReducer,
  lightbox: lightboxReducer,
  livemapData: livemapDataReducer,
  logActionResponses: logActionsReducer,
  logMgmtResponses: logMgmtResponsesReducer,
  routing: routerReducer,
  mobileNavSelection: mobileNavReducer,
  map: mapReducer
});

export default rootReducer;

// export our basemap layer selector from our root reducer to make it easier to import into components
export const getSelectedLayer = state => fromMapRedeucer.selectedLayer(state);
