import PropTypes from "prop-types";
import React, { Component } from "react";
import Collapse from "react-collapse";
import Select from "react-select";
import cx from "classnames";

import EnvInfoRow from "./EnvInfoRow.jsx";
import { livemapSoilUrl, livemapWeatherUrl } from "../../constants/config";

class EnvironmentInfo extends Component {
  static propTypes = {
    handleArrowClick: PropTypes.func.isRequired,
    detailSection: PropTypes.string.isRequired,
    sectionHeight: PropTypes.number.isRequired
  };

  constructor() {
    super();
    this.makeScrollable = this.makeScrollable.bind(this);
  }

  makeScrollable(height, opened) {
    const target = this.refs.envGrid;
    if (opened) {
      target.classList.add("scroll");
    } else {
      target.classList.remove("scroll");
    }
  }

  render() {
    const {
      handleArrowClick,
      detailSection,
      sectionHeight,
      acres,
      elev_min,
      elev_max,
      habitat,
      ins_avg,
      om,
      pct_clay,
      pct_sand,
      pct_silt,
      precip_avg,
      temp_avg,
      texture,
      veg_class,
      soilStations,
      weatherStations
    } = this.props;

    const envOpened = detailSection === "ENV";

    const titleClassNames = cx({
      "title-and-arrow": true,
      transparent: true,
      opened: envOpened
    });

    return (
      <section ref="section" className="env-info transparent">
        <div
          ref="titleArrow"
          className={titleClassNames}
          onClick={() => handleArrowClick("ENV")}
        >
          <h2 className="title">Environment</h2>
        </div>

        <Collapse
          className="details transparent"
          isOpened={envOpened}
          fixedHeight={sectionHeight}
        >
          <div ref="envGrid" className="env-grid scroll">
            {acres && precip_avg ? (
              <EnvInfoRow
                title1={acres}
                subTitle1={"acres"}
                title2={precip_avg}
                measure2={"in"}
                subTitle2={"mean precipitation"}
              />
            ) : null}

            {temp_avg && ins_avg ? (
              <EnvInfoRow
                title1={temp_avg}
                measure1={"˚f"}
                subTitle1={"mean temperature"}
                title2={ins_avg}
                measure2={""}
                subTitle2={"mean insolation"}
              />
            ) : null}

            {elev_max && elev_min ? (
              <EnvInfoRow
                title1={elev_max}
                subTitle1={"max elevation"}
                title2={elev_min}
                measure1={"ft"}
                subTitle2={"min elevation"}
              />
            ) : null}

            {pct_clay && pct_sand ? (
              <EnvInfoRow
                title1={pct_clay}
                measure1={"%"}
                subTitle1={"clay"}
                title2={pct_sand}
                measure2={"%"}
                subTitle2={"sand"}
              />
            ) : null}

            {pct_silt && om ? (
              <EnvInfoRow
                title1={pct_silt}
                measure1={"%"}
                subTitle1={"silt"}
                title2={om}
                subTitle2={"om"}
              />
            ) : null}

            {habitat ? (
              <EnvInfoRow
                title1={habitat}
                subTitle1={"habitat"}
                single={true}
              />
            ) : null}

            {veg_class ? (
              <EnvInfoRow
                title1={veg_class}
                subTitle1={"vegetation classification"}
                single={true}
              />
            ) : null}

            {texture ? (
              <EnvInfoRow
                title1={texture}
                subTitle1={"soil texture"}
                single={true}
              />
            ) : null}

            {envOpened ? (
              <div className="external-links">
                {weatherStations && weatherStations.length > 0 && (
                  <Select
                    placeholder="Live weather"
                    options={weatherStations.map(v => ({
                      label: v.name,
                      value: v.name
                    }))}
                    onChange={({ value }) => {
                      window.open(livemapWeatherUrl(value), "_blank");
                    }}
                    searchable={false}
                  />
                )}
                {soilStations && soilStations.length > 0 && (
                  <Select
                    placeholder="Soil moisture"
                    options={soilStations.map(v => ({
                      label: v.name,
                      value: v.name
                    }))}
                    onChange={({ value }) => {
                      window.open(livemapSoilUrl(value), "_blank");
                    }}
                    searchable={false}
                  />
                )}
              </div>
            ) : null}
          </div>
        </Collapse>
      </section>
    );
  }
}

export default EnvironmentInfo;
