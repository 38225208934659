import { singleLineString } from "./app";

// CARTO account and data tables configuration
export const user = "mpgranch";
export const action_reports_table = "mpg_habitat_reports";
export const management_units_table = "mu_6";
export const mgmt_units_metadata_table = "mpg_ranch_mgmt_unit_metadata"; // not being used
export const log_actions_table = "log_action_responses";
export const log_mgmt_responses_table = "log_management_responses";
export const mgmt_units_env_photos_table = "mu_environment_photos_responses";
export const carto_server = `https://${user}.carto.com`;
export const sql_endpoint = `${carto_server}/api/v2/sql?`;
export const report_data_endpoint = "";
export const live_data_map_url = "https://livemap.mpgranch.com/#/";
export const livecams_table = "mpg_ranch_livecams";
export const mu_to_livecam_table = "mu_to_livecam";
export const soil_stations_table = "mpg_ranch_soil_stations";
export const weather_stations_table = "mpg_ranch_weather_stations";

// map tile URLs
const customAerialBaseURL = "https://d2lx7rsbrticrg.cloudfront.net";
const zxy = "{z}/{x}/{y}.png";

const customAerial2011 = `${customAerialBaseURL}/2011/${zxy}`;
const customAerial2013 = `${customAerialBaseURL}/2013/${zxy}`;
const customAerial2014 = `${customAerialBaseURL}/2014/${zxy}`;
const customAerial2015 = `${customAerialBaseURL}/2015-clip/${zxy}`;
const customAerial2016 = `${customAerialBaseURL}/2016/${zxy}`;
const customAerial2017 = `${customAerialBaseURL}/2017c_jan18clip/${zxy}`;
const customAerial2018 = `${customAerialBaseURL}/2018/${zxy}`;
const customAerial2020 = `${customAerialBaseURL}/2020/${zxy}`;
const customAerial2021 = `${customAerialBaseURL}/2021/${zxy}`;
const avgSolarIns = `${customAerialBaseURL}/avg_slr/${zxy}`;
const ndvi2015 = `${customAerialBaseURL}/2015_NDVI/${zxy}`;
const mapStackTerrain = `https://sm.mapstack.stamen.com/(terrain,$a9aea7[multiply@50p])/${zxy}`;

// basemap layers array which gets used by map reducer, MapLayersTab, & LeafletMap
export const basemapLayers = [
  {
    id: "aerialImagery2011",
    url: customAerial2011,
    label: "2011 Aerial Imagery"
  },
  {
    id: "aerialImagery2013",
    url: customAerial2013,
    label: "2013 Aerial Imagery"
  },
  {
    id: "aerialImagery2014",
    url: customAerial2014,
    label: "2014 Aerial Imagery"
  },
  {
    id: "aerialImagery2015",
    url: customAerial2015,
    label: "2015 Aerial Imagery"
  },
  {
    id: "aerialImagery2016",
    url: customAerial2016,
    label: "2016 Aerial Imagery",
    bounds: [[46.664, -114.046], [46.777, -113.919]]
  },
  {
    id: "aerialImagery2017",
    url: customAerial2017,
    label: "2017 Aerial Imagery"
  },
  {
    id: "aerialImagery2018",
    url: customAerial2018,
    label: "2018 Aerial Imagery"
  },
  {
    id: "aerialImagery2020",
    url: customAerial2020,
    label: "2020 Aerial Imagery"
  },
  {
    id: "aerialImagery2021",
    url: customAerial2021,
    label: "2021 Aerial Imagery"
  },
  {
    id: "avgSolarIns",
    url: avgSolarIns,
    label: "Solar Radiation"
  },
  {
    id: "ndvi2015",
    url: ndvi2015,
    label: "2015 NDVI"
  },
  {
    id: "terrain",
    url: mapStackTerrain,
    label: "Terrain"
  }
];

// email address for MPG Ranch to receive feedback on
export const mailToAddress = "restoration.info@mpgranch.com";
export const feedbackPW = "mpg";

// about MPG Ranch text, appears in "About this Map" modal
export const aboutMapText = singleLineString`
Set in the heart of Montana’s Bitterroot Valley, MPG Ranch lies on over
14,000 acres of rich undeveloped landscape. Established in 2009
and privately owned, MPG strives to preserve the natural
communities that make this area beautiful and focuses on research
to restore and protect native diversity. The Habitat Restoration Map
captures our ongoing management plans and actions and provides
a venue for discussion of restoration research and practices.
  `;

export const search_config = {
  place_holder: "Search",
  tables: [
    {
      name: "mu_6",
      fields_to_search: ["name", "habitat"],
      fields_to_return: ["name"],
      additional_filters: [],
      nameAccessor: d => d.name
    },
    {
      name: "mpg_habitat_reports",
      fields_to_search: ["report_name", "report_tags"],
      fields_to_return: ["report_name", "report_mu", "report_id"],
      additional_filters: ["published=true"],
      nameAccessor: d => {
        return {
          name: window.decodeURIComponent(d.report_name),
          mu: d.report_mu,
          id: d.report_id
        };
      }
    }
  ]
};

export const liveCamSql = singleLineString`
SELECT entity.name AS name, join_table.mu_name AS mgmt_unit_name
FROM ${livecams_table} entity
JOIN ${mu_to_livecam_table} join_table ON entity.active = true AND join_table.camera_name = entity.name
ORDER BY entity.name, join_table.mu_name`;

export const soilStationSql = singleLineString`
SELECT entity.name AS name, mu.name AS mgmt_unit_name
FROM ${soil_stations_table} entity
JOIN mu_6 mu ON ST_Within(entity.the_geom, mu.the_geom)
ORDER BY entity.name`;

export const weatherStationSql = singleLineString`
SELECT entity.id AS name, mu.name AS mgmt_unit_name
FROM ${weather_stations_table} entity
JOIN mu_6 mu ON ST_Within(entity.the_geom, mu.the_geom)
ORDER BY entity.id`;

export const livemapLiveCamUrl = (name, mgmtUnitName) => {
  return `${live_data_map_url}?livecam=${encodeURIComponent(
    name
  )}&livecams=true&livecamPreset=${encodeURIComponent(mgmtUnitName)}`;
};

export const livemapSoilUrl = name => {
  return `${live_data_map_url}?soilStation=${encodeURIComponent(
    name
  )}&soil=true`;
};

export const livemapWeatherUrl = name => {
  return `${live_data_map_url}?weatherStation=${encodeURIComponent(
    name
  )}&weather=true`;
};
