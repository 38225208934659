import * as actions from "../constants/action_names";

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actions.LOG_MGMT_DATA_REQUEST:
      return {
        ...state,
        fetchingLogMgmtResponsesData: true,
        data: null
      };
    case actions.LOG_MGMT_DATA_RESPONSE:
      return {
        ...state,
        fetchingLogMgmtResponsesData: false,
        data: action.json
      };
    case actions.LOG_MGMT_DATA_ERROR_RESPONSE:
      return {
        ...state,
        fetchingLogMgmtResponsesData: false,
        error: action.error,
        data: null
      };
    default:
      //
  }

  return state;
};

export default reducer;
