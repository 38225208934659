import fetch from 'isomorphic-fetch';
import * as config from '../constants/config.js';
import * as actions from '../constants/action_names.js';
import { singleLineString } from '../constants/app.js';
import mapKeys from 'lodash.mapkeys';

// Actions for fetching data from CartoDB using isomorphic-fetch & CartoDB's SQL API
// NOTE: There are one set of actions for mgmt unit environment data, another for the polygons
// yes it's duplicate work, but why not leverage CartoDB's SQL API and avoid parsing
// GeoJSON feature properties on the clientside?

// Management Units attribute data
const requestMgmtUnitsData = () => ({
  type: actions.MGMT_UNITS_DATA_REQUEST
});

const receiveMgmtUnitsData = (json) => ({
  type: actions.MGMT_UNITS_DATA_RESPONSE,
  json
});

const receiveMgmtUnitsDataError = (error) => ({
  type: actions.MGMT_UNITS_DATA_ERROR_RESPONSE,
  error
});

export const fetchMgmtUnitsData = () => {
  const sql = singleLineString`
    SELECT cartodb_id, id, name,
      ROUND(acres::numeric, 2) acres,
      ROUND(ins_avg::numeric, 2) ins_avg,
      ROUND(temp_avg::numeric, 2) temp_avg,
      elev_max, elev_min, habitat, om, pct_clay, pct_sand, pct_silt,
      precip_avg, texture, veg_class
    FROM ${config.management_units_table} mu
    WHERE name != '' AND name IS NOT null
    ORDER BY CASE WHEN name = 'MPG Ranch' THEN 1 ELSE 2 END, name ASC`;
  const url = `${config.sql_endpoint}q=${sql}`;
  return (dispatch) => {
    dispatch(requestMgmtUnitsData());
    return fetch(url)
     .then(response => response.json())
     .then(json => dispatch(receiveMgmtUnitsData(mapKeys(json.rows, 'name'))))
     .catch(error => dispatch(receiveMgmtUnitsDataError(error.message)));
  };
};

// Management Units GeoJSON polygons
const requestMgmtUnitsPolygons = () => ({
  type: actions.MGMT_UNITS_POLYGONS_REQUEST
});

const receiveMgmtUnitsPolygons = (geojson) => ({
  type: actions.MGMT_UNITS_POLYGONS_RESPONSE,
  geojson
});

const receiveMgmtUnitsPolygonsError = (error) => ({
  type: actions.MGMT_UNITS_POLYGONS_ERROR_RESPONSE,
  error
});

export const fetchMgmtUnitsPolygons = () => {
  // make sure to keep the MPG Ranch polygon below everything else
  const sql = singleLineString`SELECT btrim(name) AS name, the_geom, cartodb_id
    FROM ${config.management_units_table}
    WHERE name != '' AND name IS NOT null
    ORDER BY CASE WHEN name = 'MPG Ranch' THEN 1 ELSE 2 END, NAME ASC`;
  const url = `${config.sql_endpoint}format=GeoJSON&q=${sql}`;
  return (dispatch) => {
    dispatch(requestMgmtUnitsPolygons());
    return fetch(url)
     .then(response => response.json())
     .then(json => dispatch(receiveMgmtUnitsPolygons(json)))
     .catch(error => dispatch(receiveMgmtUnitsPolygonsError(error.message)));
  };
};

// Management Units METADATA
const requestMgmtUnitsMetadata = () => ({
  type: actions.MGMT_UNITS_METADATA_REQUEST
});

const receiveMgmtUnitsMetadata = (json) => ({
  type: actions.MGMT_UNITS_METADATA_RESPONSE,
  json
});

const receiveMgmtUnitsMetadataError = (error) => ({
  type: actions.MGMT_UNITS_METADATA_ERROR_RESPONSE,
  error
});

export const fetchMgmtUnitsMetadata = () => {
  const sql = singleLineString`
    SELECT column_name, display_name, description, units_of_measure
    FROM ${config.mgmt_units_metadata_table} order by column_name`;
  const url = `${config.sql_endpoint}q=${sql}`;
  return (dispatch) => {
    dispatch(requestMgmtUnitsMetadata());
    return fetch(url)
     .then(response => response.json())
     .then(json => dispatch(receiveMgmtUnitsMetadata(json.rows)))
     .catch(error => dispatch(receiveMgmtUnitsMetadataError(error.message)));
  };
};

// Action Log Response Data
const requestLogActionsData = () => ({
  type: actions.LOG_ACTIONS_DATA_REQUEST
});

const receiveLogActionsData = (json) => ({
  type: actions.LOG_ACTIONS_DATA_RESPONSE,
  json
});

const receiveLogActionsDataError = (error) => ({
  type: actions.LOG_ACTIONS_DATA_ERROR_RESPONSE,
  error
});

export const fetchLogActionsData = () => {
  const sql = `SELECT * FROM ${config.log_actions_table}
    WHERE management_unit != '' AND management_unit IS NOT NULL
    ORDER BY "timestamp"::timestamp DESC`;
  const url = `${config.sql_endpoint}q=${sql}`;
  return (dispatch) => {
    dispatch(requestLogActionsData());
    return fetch(url)
      .then(response => response.json())
      .then(json => dispatch(receiveLogActionsData(json.rows)))
      .catch(error => dispatch(receiveLogActionsDataError(error.message)));
  };
};

// Management Log Response Data
const requestLogMgmtData = () => ({
  type: actions.LOG_MGMT_DATA_REQUEST
});

const receiveLogMgmtData = (json) => ({
  type: actions.LOG_MGMT_DATA_RESPONSE,
  json
});

const receiveLogMgmtDataError = (error) => ({
  type: actions.LOG_MGMT_DATA_ERROR_RESPONSE,
  error
});

export const fetchLogMgmtData = () => {
  const sql = singleLineString`SELECT cartodb_id, constraints, date_season_updated,
    egnyte_photo_public_token, goals, management_unit, photo_caption, plans, site_history,
    timestamp FROM ${config.log_mgmt_responses_table}
    WHERE management_unit != '' AND management_unit IS NOT NULL`;
  const url = `${config.sql_endpoint}q=${sql}`;
  return (dispatch) => {
    dispatch(requestLogMgmtData());
    return fetch(url)
      .then(response => response.json())
      .then(json => dispatch(receiveLogMgmtData(json.rows)))
      .catch(error => dispatch(receiveLogMgmtDataError(error.message)));
  };
};

// Environment Photo public tokens from Egnyte
const requestEnvPhotos = () => ({
  type: actions.MGMT_UNITS_ENV_PHOTOS_REQUEST
});

const receiveEnvPhotos = (json) => ({
  type: actions.MGMT_UNITS_ENV_PHOTOS_RESPONSE,
  json
});

const receiveEnvPhotosError = (error) => ({
  type: actions.MGMT_UNITS_ENV_PHOTOS_ERROR_RESPONSE,
  error
});

export const fetchEnvPhotos = () => {
  // the ORDER BY is important as we always want the most recently added
  // "featured photo to appear first in the image carousel"
  const sql = singleLineString`SELECT btrim(management_unit) AS management_unit,
    egnyte_photo_public_token, featured_photo, photo_description, timestamp
    FROM ${config.mgmt_units_env_photos_table}
    WHERE management_unit != '' AND management_unit IS NOT NULL
    ORDER BY management_unit ASC, featured_photo DESC, timestamp DESC`;
  const url = `${config.sql_endpoint}q=${sql}`;
  return dispatch => {
    dispatch(requestEnvPhotos());
    return fetch(url)
      .then(response => response.json())
      .then(json => dispatch(receiveEnvPhotos(json.rows)))
      .catch(error => dispatch(receiveEnvPhotosError(error)));
  };
};

// Fetching action report data
const requestReportsData = () => ({
  type: actions.REPORTS_DATA_REQUEST
});

const receiveReportsData = (json) => ({
  type: actions.REPORTS_DATA_RESPONSE,
  json: json.map(row => Object.keys(row).reduce((acc, k) => {
    acc[k] = decodeURIComponent(row[k]);
    return acc;
  }, {}))
});

const receiveReportsDataError = (error) => ({
  type: actions.REPORTS_DATA_ERROR,
  error
});

export const fetchReportsData = () => {
  const sql = singleLineString`
    SELECT cartodb_id, report_id, report_name, report_authors, report_tags, report_data,
      last_updated, report_mu, report_date, report_type
    FROM ${config.action_reports_table}
    WHERE published = true
    ORDER BY last_updated DESC`;
  const url = `${config.sql_endpoint}q=${sql}`;
  return dispatch => {
    dispatch(requestReportsData());
    return fetch(url)
      .then(response => response.json())
      .then(json => dispatch(receiveReportsData(json.rows)))
      .catch(error => dispatch(receiveReportsDataError(error)));
  };
};

const requestLivemapData = () => ({
  type: actions.LIVEMAP_DATA_REQUEST
});

const receiveLivemapData = (jsons) => {
  const [liveCams, soilStations, weatherStations] = jsons;
  return {
    type: actions.LIVEMAP_DATA_RESPONSE,
    liveCams,
    soilStations,
    weatherStations
  };
};

const receiveLivemapDataError = (error) => ({
  type: actions.LIVEMAP_DATA_ERROR_RESPONSE,
  error
});

export const fetchLivemapData = () => {
  const urls = [
    `${config.sql_endpoint}q=${config.liveCamSql}`,
    `${config.sql_endpoint}q=${config.soilStationSql}`,
    `${config.sql_endpoint}q=${config.weatherStationSql}`,
  ];
  return dispatch => {
    dispatch(requestLivemapData());
    return Promise.all(urls.map(url => fetch(url)))
      .then(responses => Promise.all(responses.map(response => response.json())))
      .then(jsons => dispatch(receiveLivemapData(jsons.map(json => json.rows))))
      .catch(error => dispatch(receiveLivemapDataError(error)));
  };
};
