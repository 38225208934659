import PropTypes from "prop-types";
import React, { Component } from "react";
import LeafletMap from "./LeafletMap.jsx";

class LeafletMapContainer extends Component {
  static propTypes = {
    selectedUnit: PropTypes.string,
    onMapMoved: PropTypes.func.isRequired,
    updateSelectedMgmtUnit: PropTypes.func.isRequired,
    resetCurrentSlideIndex: PropTypes.func.isRequired,
    updateMobileNavSelection: PropTypes.func.isRequired,
    displayMobile: PropTypes.bool,
    selectedNav: PropTypes.bool
  };

  render() {
    return <LeafletMap {...this.props} />;
  }
}

export default LeafletMapContainer;
