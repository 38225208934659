import PropTypes from "prop-types";
import React, { Component } from "react";
import cx from "classnames";
import Collapse from "react-collapse";
import MgmtUnitListItem from "./MgmtUnitListItem.jsx";
import ActionListItem from "./ActionListItem.jsx";
import SearchInput from "./SearchInput.jsx";

import { search_config } from "../constants/config";

class MgmtUnitListContainer extends Component {
  static propTypes = {
    selectMgmtUnit: PropTypes.func.isRequired,
    openMUList: PropTypes.func.isRequired,
    closeMUList: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      searchString: "",
      listOpened: props.muListOpen,
      scrollTop: 0,
      unitContainers: this.buildUnitContainers(0, this.props.unitIds.length)
    };
    this.handleOnFocus = this.handleOnFocus.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnOptions = this.handleOnOptions.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleArrowClick = this.handleArrowClick.bind(this);
    this.onActionItemClick = this.onActionItemClick.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { displayMobile, selectedNav } = nextProps;
    // logic for possible secenarios of handling opening & closing of the listOpened state
    if (
      !this.props.muListOpen &&
      !nextProps.selectedMgmtUnit &&
      nextProps.muListOpen
    ) {
      // list is closed, no MU selected, & user clicks arrow or search text field, open the MU list
      this.setState({ listOpened: true });
    } else if (this.props.muListOpen && !nextProps.muListOpen) {
      // user clicks arrow when list already opened & no MU Selected, close list
      this.setState({ listOpened: false });
    } else if (this.props.muListOpen && nextProps.selectedMgmtUnit) {
      // user selects MU from List, list already opened, close list
      this.setState({ listOpened: false });
    } else if (
      this.props.selectedMgmtUnit &&
      !nextProps.selectedMgmtUnit &&
      !nextProps.muListOpen
    ) {
      // user clicks arrow when MU Detail open, both MU Detail & List should collapse
      this.setState({ listOpened: false });
    } else if (
      this.props.selectedMgmtUnit &&
      !nextProps.selectedMgmtUnit &&
      nextProps.muListOpen
    ) {
      // user clicks back button from MU Detail, open the MU List
      this.setState({ listOpened: true });
    } else if (displayMobile && selectedNav) {
      // on mobile the MU List is open by default
      this.setState({ listOpened: true });
    }
  }

  componentDidUpdate() {
    // make sure the list is in the last place the user was scrolling to
    if (this.state.listOpened) {
      this.refs.scrollbox.scrollTop = this.state.scrollTop;
    }
    if (
      !this.state.listOpened &&
      !this.props.selectedMgmtUnit &&
      this.state.scrollTop > 0
    ) {
      // this.refs.scrollbox.scrollTop = 0;
      this.setState({ scrollTop: 0 });
    }
  }

  handleClear() {
    this.setState({ searchString: "" });
  }

  buildUnitContainers(start, end) {
    let unitContainers = [];
    const {
      units,
      unitIds,
      selectMgmtUnit,
      envPhotoData,
      closeMUList
    } = this.props;

    for (let i = start; i < end; i++) {
      let unit = units[unitIds[i]];
      let token;

      let photoInfo = envPhotoData.find(row => {
        return row.management_unit === unit.name && row.featured_photo;
      });

      if (photoInfo && photoInfo.egnyte_photo_public_token) {
        token = photoInfo.egnyte_photo_public_token;
      }

      unitContainers.push(
        <MgmtUnitListItem
          key={unit.id}
          name={unit.name}
          photoToken={token}
          handleClick={name => {
            selectMgmtUnit(name);
            closeMUList();
          }}
        />
      );
    }
    return unitContainers;
  }

  filterManagementUnits() {
    const { unitContainers, searchResults } = this.state;

    let results = unitContainers.concat();
    if (!searchResults) return results;

    if (searchResults.hasOwnProperty("mu_6")) {
      return results.filter(unit => {
        let found = searchResults["mu_6"].filter(val => {
          return unit.props.name.toLowerCase().match(val.trim().toLowerCase());
        });
        return found.length;
      });
    }

    return results;
  }

  actionItems() {
    const { searchResults } = this.state;
    let results = [];
    if (!searchResults) return results;
    if (searchResults.hasOwnProperty("mpg_habitat_reports")) {
      results = searchResults["mpg_habitat_reports"].map(d => {
        return (
          <ActionListItem
            key={d.id}
            name={d.name}
            action_id={d.id}
            mu={d.mu}
            handleClick={this.onActionItemClick}
          />
        );
      });
    }

    return results;
  }

  onActionItemClick(evt, id) {
    evt.stopPropagation();
    evt.preventDefault();

    const {
      closeMUList,
      actionsCombined,
      openLightbox
    } = this.props;

    let f = actionsCombined.filter(d => {
      return d.report_id === id;
    });

    if (f.length) {
      openLightbox({ slides: f[0] });
      // selectMgmtUnit(name, 'ACTIONS');
      closeMUList();
      this.setState({ searchResults: null });
      return false;
    }

    return false;
  }

  handleOnChange(evt) {
    this.setState({ searchString: evt.target.value });
  }

  handleOnOptions(options) {
    this.setState({ searchResults: options });
  }

  handleArrowClick() {
    // if list is open
    if (this.props.muListOpen) {
      this.props.closeMUList();
    } else {
      if (this.props.selectedMgmtUnit) {
        this.props.deselectMgmtUnit();
        this.props.closeMUList();
      } else {
        this.props.openMUList();
      }
    }
  }

  handleOnFocus() {
    if (!this.props.muListOpen) {
      if (this.props.selectedMgmtUnit) {
        this.props.deselectMgmtUnit();
      }
      this.props.openMUList();
    }
  }

  handleOnBlur() {}

  handleScroll(e) {
    let scrollTop = this.refs.scrollbox.scrollTop;
    this.setState({ scrollTop });
  }

  renderListItems() {
    let mgmtUnits = this.filterManagementUnits();
    let actionUnits = this.actionItems();

    return mgmtUnits.concat(actionUnits);
  }

  render() {
    const { searchString } = this.state;
    const { appHeight, displayMobile, selectedNav, selectedMgmtUnit, } = this.props;
    let unitListHeight = appHeight - 75;

    let listContainerClassNames = cx({
      "mu-list-container": true,
      open: this.state.listOpened,
      "ui-container": true,
      "top right": true
    });

    let arrowClassNames = cx({
      flipped: this.state.listOpened || selectedMgmtUnit,
      icon: true,
      "icon-arrow": true
    });

    let style = {
      display: displayMobile && !selectedNav ? "none" : "block"
    };

    return (
      <div
        ref="muListContainer"
        className={listContainerClassNames}
        style={style}
      >
        <div className="search-box-container">
          <span className="icon icon-search"></span>
          <SearchInput
            placeHolder={search_config.place_holder}
            value={searchString}
            onChange={this.handleOnChange}
            onFocus={this.handleOnFocus}
            onBlur={this.handleOnBlur}
            onOptions={this.handleOnOptions}
          />
          <span className="search-clear" onClick={this.handleClear.bind(this)}>
            &times;
          </span>
          {!displayMobile && (
            <span
              className={arrowClassNames}
              onClick={() => this.handleArrowClick()}
            ></span>
          )}
        </div>
        <Collapse
          isOpened={this.state.listOpened}
          className="unit-list-container"
          fixedHeight={unitListHeight}
        >
          <div
            className="mu-unit-list"
            ref="scrollbox"
            style={{ height: unitListHeight }}
            onScroll={this.handleScroll}
          >
            {this.renderListItems()}
          </div>
        </Collapse>
      </div>
    );
  }
}

export default MgmtUnitListContainer;
