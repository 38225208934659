import React, { Component } from "react";
import { formatImages } from "../constants/app.js";
import ImageCarousel from "./ImageCarousel.jsx";
import ReportSlides from "./ReportSlides.jsx";
import AboutAndSubmitFeedback from "./AboutAndSubmitFeedback.jsx";

// Code borrowed && ES6-ified from https://github.com/howtomakeaturn/React-Lightbox
// CSS from http://stackoverflow.com/questions/19064987/html-css-popup-div-on-text-click
// and http://stackoverflow.com/questions/10019797/pure-css-close-button

class Lightbox extends Component {
  constructor() {
    super();
    this.processedImageData = [];
  }

  componentWillReceiveProps({
    photos,
    slides,
    lightBoxOpened,
    viewportWidth,
    viewportHeight
  }) {
    if (lightBoxOpened && photos) {
      this.processedImageData = formatImages(photos, {
        width: viewportWidth,
        height: viewportHeight
      });
    }
    if (lightBoxOpened && !photos) {
      this.processedImageData = [];
    }
  }

  render() {
    const {
      lightBoxOpened,
      slides,
      lightBoxIndex,
      setCurrentSlideIndex,
      closeLightbox,
      queryParams,
      viewportWidth,
      feedback
    } = this.props;

    if (lightBoxOpened) {
      return (
        <div className="light-box">
          <div className="black-overlay" onClick={() => closeLightbox()} />
          <div className="white-content">
            <button className="close" title="close" onClick={() => closeLightbox()}>
              &times;
            </button>
            {this.processedImageData.length ? (
              <ImageCarousel
                onSlideChange={setCurrentSlideIndex}
                processedImageData={this.processedImageData}
                {...{ lightBoxOpened, lightBoxIndex }}
              />
            ) : slides ? (
              <ReportSlides {...slides} {...{ viewportWidth }} />
            ) : (
              <AboutAndSubmitFeedback
                {...{ closeLightbox, queryParams, feedback }}
              />
            )}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default Lightbox;
