import {
  REPORTS_DATA_REQUEST,
  REPORTS_DATA_RESPONSE,
  REPORTS_DATA_ERROR
} from "../constants/action_names";

const reducer = (state = {}, action) => {
  switch (action.type) {
    case REPORTS_DATA_REQUEST:
      return {
        _fetchingReportsData: true,
        data: null
      };
    case REPORTS_DATA_RESPONSE:
      return {
        _fetchingReportsData: false,
        data: action.json
      };
    case REPORTS_DATA_ERROR:
      return {
        _fetchingReportsData: false,
        data: null,
        error: action.error
      };
    default:
      return state;
  }
};

export default reducer;
