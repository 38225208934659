import React, { Component } from "react";
import Collapse from "react-collapse";
import { basemapLayers } from "../constants/config";

class MapLayersTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: props.map.layersTabOpen
    };
  }

  componentWillReceiveProps(nextProps) {
    const { displayMobile, selectedNav, map } = nextProps;
    const { layersTabOpen } = map;

    if (layersTabOpen !== this.props.layersTabOpen) {
      this.setState({
        opened: layersTabOpen
      });
    }

    if (displayMobile && selectedNav !== this.props.selectedNav) {
      this.props.layersTabOpen();
    }
  }

  handleClick() {
    this.props.layersTabOpen();
  }

  render() {
    const { onLayerSelectorClick, selectedLayer, displayMobile, selectedNav } = this.props;
    let style = {
      display: displayMobile && !selectedNav ? "none" : "block"
    };

    return (
      <div className="map-layers-tab ui-container bottom left" style={style}>
        <Collapse
          isOpened={this.state.opened}
          className="map-layers-list-container"
        >
          <ul>
            {basemapLayers.map(layer => {
              return (
                <li
                  key={layer.id}
                  style={{
                    backgroundColor: layer.id === selectedLayer ? "#e8d4b0" : ""
                  }}
                  onClick={() => onLayerSelectorClick(layer.id)}
                >
                  {layer.label}
                </li>
              );
            })}
          </ul>
        </Collapse>
        <div className="tab" onClick={() => this.handleClick()}>
          <span className="icon"></span>
          <h3 className="title">Layers</h3>
        </div>
      </div>
    );
  }
}

export default MapLayersTab;
