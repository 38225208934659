import React, { Component } from "react";
import queryString from "query-string";

import { initialMapView } from "../constants/app.js";

import LeafletMapContainer from "../containers/LeafletMapConnected.jsx";
import MgmtUnitListContainer from "../containers/MgmtUnitListContainerConnected.jsx";
import MapLayersTab from "../containers/MapLayersTabConnected.jsx";
import MapLegend from "../containers/MapLegendConnected.jsx";
import MgmtUnitDetail from "../containers/MgmtUnitDetailConnected.jsx";
import MobileNav from "../components/MobileNav.jsx";
import RecentActionsTab from "../containers/RecentActionsTabConnected.jsx";
import Lightbox from "../containers/LightBoxConnected.jsx";

// main app container
export default class App extends Component {
  constructor(props, context) {
    super(props, context);
    this.mapOptions = null;
    this.firstPageLoad = true;
    this.onMapMoved = this.onMapMoved.bind(this);
  }

  componentWillMount() {
    const { query } = this.props;
    // set map zoom & center from URL query params
    if (
      query &&
      query.zoom &&
      query.center &&
      query.center.lat &&
      query.center.lng
    ) {
      const { zoom, center, mu, report } = query;
      this.mapOptions = { zoom, center };
      if (query.mu) {
        this.selectedUnit = mu;
      }
      if (query.report) {
        this.selectedReport = report;
      }
    } else {
      this.mapOptions = initialMapView;
    }
  }

  componentDidMount() {
    this.props.actions.fetchAppData();
  }

  componentWillReceiveProps(nextProps, nextState) {
    // after logActionResponses & action report data has been fetched combine & normalize them
    if (
      nextProps.recentActions &&
      nextProps.reports &&
      nextProps.mgmtUnits.envPhotoData &&
      !this.props.actionsCombined.length
    ) {
      this.props.actions.combineActionLogsReports(
        nextProps.recentActions,
        nextProps.reports,
        nextProps.mgmtUnits.envPhotoData
      );
    }
  }

  componentDidUpdate(prevProps) {
    // redux action creators for this component are contained within "props.actions"
    // not to be confused with recentActions, etc.
    const {
      mgmtUnits,
      reports,
      recentActions,
      logMgmtResponses,
      query,
      actions,
      lightbox
    } = this.props;
    const {
      data,
      detailSection,
      envPhotoData,
      selectedUnit,
      geojson
    } = mgmtUnits;
    const selectedReport = lightbox ? lightbox.selectedReport : null;

    // manually select an MU if there is one in the URL hash when the page first loads
    // Also, if there's a report in the hash, open its lightbox
    if (
      data &&
      envPhotoData &&
      reports &&
      recentActions &&
      logMgmtResponses &&
      geojson &&
      this.firstPageLoad
    ) {
      if (query && (query.mu || query.report)) {
        this.firstPageLoad = false;
        if (query.mu) {
          actions.selectMgmtUnit(query.mu, detailSection);
        }
        if (query.report) {
          const data = reports.find(row => {
            return row.report_id === query.report;
          });
          actions.openLightbox({ slides: data });
        }
      }
    }

    // There are four cases when we might want to update the hash:
    //  1) there is a selected unit and it's different from the previous state
    //  2) there is a selected report and it's different from the previous state
    //  3) there is no selected unit, but there was one previously
    //  4) there is no selected report, but there was one previously
    if (
      (selectedUnit && selectedUnit !== prevProps.mgmtUnits.selectedUnit) ||
      (selectedReport &&
        selectedReport !== prevProps.lightbox.selectedReport) ||
      (!selectedReport && prevProps.lightbox.selectedReport) ||
      (!selectedUnit && prevProps.mgmtUnits.selectedUnit)
    ) {
      const newQuery = { ...query };
      const hash = {};
      if (newQuery.center) {
        hash.lat = newQuery.center.lat;
        hash.lng = newQuery.center.lng;
      }
      if (newQuery.zoom) {
        hash.zoom = newQuery.zoom;
      }
      if (selectedUnit && selectedReport) {
        hash.mu = selectedUnit;
        hash.report = selectedReport;
      } else if (selectedUnit && !selectedReport) {
        hash.mu = selectedUnit;
        hash.report = null;
      } else if (!selectedUnit && selectedReport) {
        hash.mu = null;
        hash.report = selectedReport;
      } else {
        hash.mu = null;
        hash.report = null;
      }
      this.updateURLHash(hash);
    }
  }

  onMapMoved(event) {
    // update the hash with map center & zoom
    if (event && event.target) {
      let query = {};
      query.zoom = event.target.getZoom();
      query.lat = event.target.getCenter().lat;
      query.lng = event.target.getCenter().lng;
      query.mu = this.props.mgmtUnits.selectedUnit;
      this.updateURLHash(query);
    }
  }

  updateURLHash(params) {
    const { mu, lat, lng, zoom, report } = params; // expects these params in an object
    let newQueryParams = {};
    if (mu || this.props.mgmtUnits.selectedUnit)
      newQueryParams.mu = mu || this.props.mgmtUnits.selectedUnit || null;
    if (report || (this.props.lightbox && this.props.lightbox.selectedReport))
      newQueryParams.report =
        report || (this.props.lightbox && this.props.lightbox.selectedReport)
          ? this.props.lightbox.selectedReport
          : null;
    newQueryParams.lat = lat || null;
    newQueryParams.lng = lng || null;
    newQueryParams.zoom = zoom || null;
    window.location.hash = `#/?${queryString.stringify(newQueryParams)}`;
  }

  render() {
    const {
      query,
      mgmtUnits,
      recentActions,
      logMgmtResponses,
      browser,
      actions,
      mobileNavSelection,
      actionsCombined
    } = this.props;
    const { geojson, data, envPhotoData } = mgmtUnits;
    // should UI elements be hidden unless they're selected by the MobileNav component?
    const displayMobile = browser.lessThan.small;

    return (
      <div className="container full-height">
        <div className="row u-full-width main">
          <header>
            <div className="header-logo-title">
              <a target="_blank" href="http://portal.mpgranch.com/" rel="noreferrer">
                <span className="logo" />
              </a>
              <h1
                onClick={() => this.props.actions.selectMgmtUnit("MPG Ranch")}
                style={{ cursor: "pointer" }}
              >
                Habitat Restoration Map
              </h1>
            </div>
            <div
              className="about"
              onClick={() => actions.openLightbox(undefined, true)}
            >
              <h3>About this map</h3>
            </div>
          </header>

          {geojson && (
            <LeafletMapContainer
              mapOptions={this.mapOptions}
              onMapMoved={this.onMapMoved}
              displayMobile={displayMobile}
              selectedNav={true}
            />
          )}

          {data && envPhotoData && (
            <MgmtUnitListContainer
              selectedNav={mobileNavSelection === "mgmt_units"}
              toggleMUListCollapsed={this.toggleMUListCollapsed}
              {...{ displayMobile }}
            />
          )}

          {actionsCombined && (
            <RecentActionsTab
              displayMobile={displayMobile}
              selectedNav={mobileNavSelection === "recent_actions"}
            />
          )}

          {recentActions && logMgmtResponses && data && envPhotoData && (
            <MgmtUnitDetail
              displayMobile={displayMobile}
              selectedNav={mobileNavSelection === "mu_detail"}
            />
          )}

          <MapLayersTab
            displayMobile={displayMobile}
            selectedNav={mobileNavSelection === "map_layers"}
          />

          <MapLegend
            {...{ displayMobile }}
            selectedNav={mobileNavSelection === "map_layers"}
          />

          {displayMobile && (
            <MobileNav updateNav={this.props.actions.mobileNavChanged} />
          )}

          {this.props.children}

          <Lightbox {...{ query }} />
        </div>
      </div>
    );
  }
}

App.contextTypes = {
  router: React.PropTypes.object.isRequired
};
