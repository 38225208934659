import PropTypes from "prop-types";
import React, { Component } from "react";
import cx from "classnames";

class MobileNav extends Component {
  static propTypes = {
    updateNav: PropTypes.func
  };

  constructor() {
    super();
    this.state = { isOpened: false };
  }

  handleClick() {
    this.setState({
      isOpened: !this.state.isOpened
    });
  }

  navigate(navRoute) {
    this.handleClick();
    this.props.updateNav(navRoute);
  }

  render() {
    const { isOpened } = this.state;
    let menuClassNames = cx({
      menu: true,
      opened: isOpened
    });

    return (
      <div className="mobile-nav">
        <div className="hamburger-icon" onClick={() => this.handleClick()}>
          {isOpened ? (
            <span className="close">X</span>
          ) : (
            <span className="hamburger">|||</span>
          )}
        </div>
        <div className={menuClassNames}>
          <ul>
            <li onClick={() => this.navigate("map")}>Map</li>
            <li onClick={() => this.navigate("map_layers")}>Map Layers</li>
            <li onClick={() => this.navigate("mgmt_units")}>
              Management Units
            </li>
            <li onClick={() => this.navigate("recent_actions")}>Recent</li>
          </ul>
        </div>
      </div>
    );
  }
}

export default MobileNav;
