import * as actions from '../constants/action_names.js';

export const openLightbox = (data = [], about = false, feedback = false) => ({
  type: actions.LIGHTBOX_OPENED,
  data,
  about,
  feedback
});

export const closeLightbox = () => ({
  type: actions.LIGHTBOX_CLOSED
});

export const setCurrentSlideIndex = (index) => ({
  type: actions.SET_CURRENT_SLIDE_INDEX,
  index
});

export const resetCurrentSlideIndex = () => ({
  type: actions.RESET_CURRENT_SLIDE_INDEX
});
