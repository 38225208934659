import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router";
import Collapse from "react-collapse";

const formatDate = dayjs => dayjs.format("MM[.]DD");
const formatText = text =>
  text.length < 30 ? text : text.substring(0, 30) + "...";

const RecentAction = ({ action }) => (
  <div>
    <div className="recent-action-date">
      <h2>{formatDate(action.date)}</h2>
    </div>
    <div className="mu-and-action-title">
      <h3>{action.management_unit}</h3>
      {action.photo_description ? (
        <p>
          <strong>Photo Update:</strong>{" "}
          {formatText(
            action.photo_description === ""
              ? action.text
              : action.photo_description
          )}
        </p>
      ) : action.action_title ? (
        <p>
          <strong>Update:</strong> {action.action_title}
        </p>
      ) : action.report_name && action.report_type === "plan" ? (
        <p>
          <strong>Plan:</strong> {action.report_name}
        </p>
      ) : action.report_name ? (
        <p>
          <strong>Action Report:</strong>
          <Link to={`/reports/${action.report_id}`}>
            {formatText(action.report_name)}
          </Link>
        </p>
      ) : action.text ? (
        <p>
          <strong>Action Update:</strong> {formatText(action.text)}
        </p>
      ) : null}
    </div>
  </div>
);

class RecentActionsTab extends Component {
  static propTypes = {
    actionsCombined: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.obj,
        management_unit: PropTypes.string,
        activity_description: PropTypes.string,
        cartodb_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      })
    ).isRequired,
    openLightbox: PropTypes.func.isRequired,
    selectMgmtUnit: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.state = {
      opened: true
    };
  }

  handleClick() {
    this.setState({
      opened: !this.state.opened
    });
  }

  render() {
    const {
      actionsCombined,
      selectMgmtUnit,
      resetCurrentSlideIndex,
      displayMobile,
      openLightbox,
      selectedNav,
    } = this.props;

    let style = {
      display: displayMobile && !selectedNav ? "none" : "block"
    };

    return (
      <div
        className="recent-actions-tab ui-container bottom left"
        style={style}
      >
        <Collapse isOpened={this.state.opened} fixedHeight={360}>
          <nav className="actions-list-container scroll">
            {actionsCombined.map((action, i) => {
              if (action.egnyte_photo_public_token_1) {
                action.egnyte_photo_public_token =
                  action.egnyte_photo_public_token_1;
                action.photo_description = action.photo_caption_1;
              }

              if (action.report_type) {
                return (
                  <div
                    key={i}
                    className="recent-action-item"
                    onClick={() => {
                      this.props.router.push(`/reports/${action.report_id}`);
                    }}
                  >
                    <RecentAction action={action} />
                  </div>
                );
              }
              return (
                <div
                  key={i}
                  className="recent-action-item"
                  onClick={() => {
                    // This is where we click on something in the recent actions.
                    // If it's a photo, we want to open the photo.
                    // If it's a report (or a plan) we want to open that, too.

                    resetCurrentSlideIndex();
                    selectMgmtUnit(action.management_unit, "ACTIONS");

                    if (action.photo_description) {
                      // it's a photo
                      // Instead of a single action, it needs to be a list of photos
                      // Might need to pass in all of the photos for an MU?
                      // For now, just open a lightbox with one photo, the one we clicked on:
                      openLightbox({ photos: [action] }, false);
                      // We also should reset the current slide index so the carousel matches.
                    } else {
                      console.log(
                        "snippet selected in recent menu. Nothing to open in lightbox: ",
                        action
                      );
                    }
                  }}
                >
                  <RecentAction action={action} />
                </div>
              );
            })}
          </nav>
        </Collapse>
        <div className="tab" onClick={() => this.handleClick()}>
          <span className="icon" />
          <h3 className="title">Recent</h3>
        </div>
      </div>
    );
  }
}

export default RecentActionsTab;
