import thunkMiddleware from "redux-thunk";
import createLogger from "redux-logger";

import * as actions from "./constants/action_names";
import { mobileNavChanged } from "./actions/mobile_nav_actions";
import { resetCurrentSlideIndex } from "./actions/lightbox_actions";

const middleware = [thunkMiddleware];

// Use envify to remove logger in prod
if (process.env.NODE_ENV === "development") {
  middleware.push(createLogger());
}

// Here we can intercept the SELECT_MGMT_UNIT and DESELECT_MGMT_UNIT actions
// and then dispatch other actions that should be fired as a result,
// this prevents having to write the same logic in multiple components that call
// the SELECT_MGMT_UNIT and DESELECT_MGMT_UNIT actions
const selectMgmtUnitMiddleware = ({ getState, dispatch }) => next => action => {
  let result;

  if (action.type === actions.SELECT_MGMT_UNIT) {
    // don't select an already selected MU
    if (getState().mgmtUnits.selectedUnit === action.unitName) {
      // how to handle?
      // return next({type: ''});
    }
    if (getState().mobileNavSelection !== "mu_detail") {
      dispatch(mobileNavChanged("mu_detail"));
    }
    result = next(action);
    return result;
  }

  if (action.type === actions.DESELECT_MGMT_UNIT) {
    dispatch(resetCurrentSlideIndex());
    result = next(action);
    return result;
  }

  return next(action);
};

middleware.push(selectMgmtUnitMiddleware);

export default middleware;
