import * as actions from "../constants/action_names.js";

export const DETAIL = {
  NONE: "",
  PHOTOS: "PHOTOS",
  ENV: "ENV",
  MGMT: "MGMT",
  ACTIONS: "ACTIONS"
};

export const defaultState = {
  detailSection: DETAIL.PHOTOS,
  muListOpen: false,
  selectedUnit: null
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    // mgmt unit env photo data
    case actions.MGMT_UNITS_ENV_PHOTOS_REQUEST:
      return {
        ...state,
        _fetchingEnvPhotoData: true,
        envPhotoData: null
      };
    case actions.MGMT_UNITS_ENV_PHOTOS_RESPONSE:
      return {
        ...state,
        _fetchingEnvPhotoData: false,
        envPhotoData: action.json
      };
    case actions.MGMT_UNITS_ENV_PHOTOS_ERROR_RESPONSE:
      return {
        ...state,
        _fetchingEnvPhotoData: false,
        envPhotoData: action.error,
        error: action.error
      };

    // mgmt unit attribute data
    case actions.MGMT_UNITS_DATA_REQUEST:
      return {
        ...state,
        _fetchingMgmtUnitsData: true,
        data: null
      };
    case actions.MGMT_UNITS_DATA_RESPONSE:
      return {
        ...state,
        _fetchingMgmtUnitsData: false,
        data: action.json
      };
    case actions.MGMT_UNITS_DATA_ERROR_RESPONSE:
      return {
        ...state,
        _fetchingMgmtUnitsData: false,
        data: action.error
      };

    // mgmt units metadata
    case actions.MGMT_UNITS_METADATA_REQUEST:
      return {
        ...state,
        _fetchingMgmtUnitsMetadata: true,
        metadata: null
      };
    case actions.MGMT_UNITS_METADATA_RESPONSE:
      return {
        ...state,
        _fetchingMgmtUnitsMetadata: false,
        metadata: action.json
      };
    case actions.MGMT_UNITS_METADATA_ERROR_RESPONSE:
      return {
        ...state,
        _fetchingMgmtUnitsData: false,
        metadata: action.error
      };

    //mgmt units polygons
    case actions.MGMT_UNITS_POLYGONS_REQUEST:
      return {
        ...state,
        _fetchingMgmtUnitsPolygons: true,
        geojson: null
      };
    case actions.MGMT_UNITS_POLYGONS_RESPONSE:
      return {
        ...state,
        _fetchingMgmtUnitsPolygons: false,
        geojson: action.geojson
      };
    case actions.MGMT_UNITS_POLYGONS_ERROR_RESPONSE:
      return {
        ...state,
        _fetchingMgmtUnitsPolygons: false,
        geojson: action.error
      };

    // select / deslect mgmt unit
    case actions.SELECT_MGMT_UNIT:
      return {
        ...state,
        selectedUnit: action.unitName,
        detailSection: action.section
      };
    case actions.DESELECT_MGMT_UNIT:
      return {
        ...state,
        selectedUnit: null,
        detailSection: action.section
      };

    // toggle MU Detail section (ENV || MGMT || ACTIONS || PHOTOS)
    case actions.TOGGLE_MU_DETAIL_SECTION:
      return {
        ...state,
        detailSection: action.section
      };

    // toggle opening / closing of the MU List Container
    case actions.OPEN_MU_LIST:
      return {
        ...state,
        muListOpen: true
      };
    case actions.CLOSE_MU_LIST:
      return {
        ...state,
        muListOpen: false
      };

    case actions.SET_MGMT_UNIT_ZOOM_CENTER:
      return {
        ...state,
        center: action.center,
        zoom: action.zoom
      };
    default:
      //
  }

  return state;
};

export default reducer;
