import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  selectMgmtUnit,
  deselectMgmtUnit,
  openMUList,
  closeMUList
} from "../actions/mgmt_unit_actions.js";
import { mobileNavChanged } from "../actions/mobile_nav_actions";
import {
  resetCurrentSlideIndex,
  openLightbox
} from "../actions/lightbox_actions.js";
import MgmtUnitListContainer from "../components/MgmtUnitListContainer.jsx";

const mapStateToProps = ({ mgmtUnits, browser, actionsCombined }) => ({
  actionsCombined,
  envPhotoData: mgmtUnits.envPhotoData,
  muListOpen: mgmtUnits.muListOpen,
  unitIds: Object.keys(mgmtUnits.data),
  units: mgmtUnits.data,
  appHeight: browser.height,
  selectedMgmtUnit: mgmtUnits.selectedUnit
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      openMUList,
      closeMUList,
      openLightbox,
      selectMgmtUnit,
      deselectMgmtUnit,
      resetCurrentSlideIndex,
      mobileNavChanged
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MgmtUnitListContainer);
