import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import {
  resetCurrentSlideIndex,
  openLightbox
} from "../actions/lightbox_actions";
import { selectMgmtUnit } from "../actions/mgmt_unit_actions";

import RecentActionsTab from "../components/RecentActionsTab.jsx";

// Because Action Reports get displayed in the same areas as Actions Logs,
// they need to be combined and then sorted by their different date fields
const mapStateToProps = ({ actionsCombined, mgmtUnits, router }, ownProps) => {
  const { selectedUnit, detailSection } = mgmtUnits;
  return {
    mgmtUnits,
    actionsCombined,
    detailSection,
    router,
    selectedMgmtUnit: selectedUnit
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        selectMgmtUnit,
        openLightbox,
        resetCurrentSlideIndex
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RecentActionsTab));
