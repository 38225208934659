import PropTypes from "prop-types";
import React, { Component } from "react";
import Frame from "react-frame-component";
import Slides from "mpg-habitat-slides-component";
import { singleLineString } from "../constants/app";

class ReportSlides extends Component {
  static propTypes = {
    slides: PropTypes.string,
    report_name: PropTypes.string,
    report_tags: PropTypes.string,
    report_authors: PropTypes.string
  };

  constructor() {
    super();
    this._reportSlides = null;
    this.state = {
      slides: [],
      index: 0
    };
  }

  componentWillMount() {
    const { report_data } = this.props;
    if (report_data) {
      this.setState({
        slides: JSON.parse(report_data)
      });
    }
  }

  goNext() {
    if (this.state.index < this.state.slides.length - 1) {
      this.setState({
        index: (this.state.index + 1)
      });
    }
  }

  goPrev() {
    if (this.state.index > 0) {
      this.setState({
        index: (this.state.index - 1)
      });
    }
  }

  render() {
    const {
      date,
      report_name,
      report_authors,
      report_tags,
      viewportWidth
    } = this.props;
    const { slides, index } = this.state;
    const containerWidth = Math.floor(viewportWidth * 0.9); // acount for 5% position left & right set by LightBox parent
    const curSlide = slides[index];
    const width = containerWidth > 420 ? 800 : "100%";
    const height = containerWidth > 420 ? 600 : "100%";
    const frameStyle = {
      width,
      height: containerWidth > 420 ? 600 : "100%",
      background: "#000",
      border: "none",
      padding: 0,
      margin: 0
    };

    const innerStyle = {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: containerWidth > 420 ? "auto" : "100%",
      height: containerWidth > 420 ? "auto" : "100%"
    };

    const mainCSS = singleLineString`
      html, body, .frame-root, .frame-content {
        width: 100%;
        height: 100%;
      }
      .slide .body a {
        outline: none;
        text-decoration: none;
        color: orange;
      }
      .slide img {
        max-width: 100%;
      }
    `;

    // NOTE: react-frame-component is used to create an iframe so that styles
    // from the main app don't pollute the styling of the slides
    // mpg-habitat-slides-component is shared with the mpg-habitat-slides-builder heroku app
    return (
      <div
        className="action-report-container"
        ref={c => (this._reportSlides = c)}
      >
        {index > 0 && (
          <span
            className="icon action-report-previous"
            onClick={this.goPrev.bind(this)}
          >
            {"‹"}
          </span>
        )}
        <Frame
          className="report-slides"
          head={[
            <base key={4} target="_blank" />,
            <link
              key={1}
              href="https://fonts.googleapis.com/css?family=Lato:100,400,700"
              rel="stylesheet"
            />,
            <link
              key={2}
              href="https://cdnjs.cloudflare.com/ajax/libs/normalize/5.0.0/normalize.min.css"
              rel="stylesheet"
            />,
            <style key={3}>{mainCSS}</style>
          ]}
          style={frameStyle}
          scrolling="no"
        >
          <div style={{ position: "relative", width, height }}>
            <div style={innerStyle}>
              <Slides
                {...curSlide}
                authors={report_authors}
                tags={report_tags}
                reportDate={date}
                reportTitle={report_name}
                slideWidth={containerWidth}
              />
            </div>
          </div>
        </Frame>
        {index < slides.length - 1 && (
          <span
            className="icon action-report-next"
            onClick={this.goNext.bind(this)}
          >
            {"›"}
          </span>
        )}
      </div>
    );
  }
}

export default ReportSlides;
