import { COMBINE_ACTION_LOGS_REPORTS } from "../constants/action_names";
import dayjs from "dayjs";

// some parts of the app require having logActionResponses & actionReports combined with certain fields normalized
// do this once on app load after data has loaded for reports && logs
const combineActionLogsReports = (logs, reports, photos) => {
  let combined = [];
  if (logs && logs.length) combined = combined.concat(logs);
  if (reports && reports.length) combined = combined.concat(reports);
  if (photos && photos.length) combined = combined.concat(photos);
  if (!combined.length) return combined;

  //  normalize the date field so that we don't have to compare different date types?
  combined.forEach(item => {
    // expecting cartodb_id as a number
    if (item.cartodb_id) item.cartodb_id = +item.cartodb_id;

    // a hack because the photos don't have cartodb_ids
    if (item.egnyte_photo_public_token)
      item.cartodb_id = item.egnyte_photo_public_token;

    // date is stored under "when" or "report_date", photos have "timestamp"
    // normalize to just "date"
    if (item.when) item.date = dayjs(item.when, "M/D/YYYY");
    if (item.report_date)
      item.date = dayjs(item.report_date, "YYYY-MM-DDTHH:mm:ssZ");
    if (item.timestamp) item.date = dayjs(item.timestamp, "M/D/YYYY HH:mm:ss");

    // MU is stored as report_mu or management unit, normalize to management unit
    if (item.report_mu) item.management_unit = item.report_mu;
  });

  // sort action reports & logs chronologically (most recent first)
  combined.sort((a, b) => {
    return b.date - a.date;
  });

  return combined;
};

const reducer = (state = [], action) => {
  switch (action.type) {
    case COMBINE_ACTION_LOGS_REPORTS:
      return combineActionLogsReports(
        action.logs,
        action.reports,
        action.reportType
      );
    default:
      return state;
  }
};

export default reducer;
