import PropTypes from "prop-types";
import React from "react";
import { buildPhotoURL } from "../constants/app.js";

const MgmtUnitListItem = props => {
  const { name, handleClick, photoToken } = props;
  const photoSize = { width: 120 };

  const thumbStyle = {
    backgroundImage: `url(${buildPhotoURL(photoToken, photoSize)})`,
    backgroundRepeat: "no-repeat"
  };

  const noThumbStyle = {
    backgroundColor: "rgba(0, 0, 0, 0.2)"
  };

  return (
    <div className="mu-list-item" onClick={() => handleClick(name)}>
      <div
        className="mu-thumb"
        style={photoToken ? thumbStyle : noThumbStyle}
      />
      <div className="mu-title">
        <h2 className="mu-name">{name}</h2>
      </div>
    </div>
  );
};

MgmtUnitListItem.propTypes = {
  photoToken: PropTypes.string,
  name: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired
};

export default MgmtUnitListItem;
