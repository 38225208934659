import PropTypes from "prop-types";
import React from "react";

const ActionListItem = props => {
  const { name, mu, action_id, handleClick } = props;

  return (
    <div
      className="mu-list-item alt"
      onClick={evt => handleClick(evt, action_id)}
    >
      <div className="mu-title">
        <div className="mu-name">
          <h2>{name}</h2>
          <p>
            Record found in <span>{mu}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

ActionListItem.propTypes = {
  name: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  mu: PropTypes.string.isRequired,
  action_id: PropTypes.string.isRequired
};

export default ActionListItem;
