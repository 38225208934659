import { connect } from "react-redux";
import { toggleBasemapLayer, layersTabOpen } from "../actions/map_actions";
import { getSelectedLayer } from "../reducers/index";
import MapLayersTab from "../components/MapLayersTab.jsx";

const mapStateToProps = state => {
  const { map } = state;
  return {
    map,
    selectedLayer: getSelectedLayer(state)
  };
};

export default connect(
  mapStateToProps,
  {
    onLayerSelectorClick: toggleBasemapLayer,
    layersTabOpen
  }
)(MapLayersTab);
