import React, { Component } from "react";
import { mailToAddress, feedbackPW, aboutMapText } from "../constants/config";

const GoBack = props => {
  return (
    <p
      className="go-back uppercase cursor-pointer"
      onClick={() => props.close()}
    >
      {props.children || "Go back to the app"}
    </p>
  );
};

class AboutAndSubmitFeedback extends Component {
  constructor() {
    super();
    this.state = {
      viewAboutMap: true,
      viewTokenSubmit: false,
      tokenValidated: false,
      displayErrMsg: false,
      tokenString: ""
    };
    this.viewTokenSubmitForm = this.viewTokenSubmitForm.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.createMailTo = this.createMailTo.bind(this);
  }

  componentWillMount() {
    // if opened from MU feedback icon, immediately display the token submit form
    if (this.props.feedback) {
      this.setState({
        viewTokenSubmit: true
      });
    }
  }

  viewTokenSubmitForm() {
    if (!this.state.viewTokenSubmitForm) {
      this.setState({
        viewTokenSubmit: true
      });
    }
  }

  handleInputChange(e) {
    this.setState({
      tokenString: e.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault(); // don't allow the page to reload
    if (this.state.tokenString === feedbackPW) {
      this.setState({
        tokenValidated: true,
        displayErrMsg: false
      });
    } else {
      this.setState({
        displayErrMsg: true,
        tokenString: ""
      });
    }
  }

  createMailTo() {
    const { queryParams } = this.props;
    const { center, zoom, mu } = queryParams;
    const { lat, lng } = center;
    const location = `Include this information with your email: \n\t Management Unit: ${mu} \n\t lat: ${lat} \n\t lng: ${lng} \n\t zoom: ${zoom}`;
    const subject = `subject=${encodeURI("Feedback on MPG Habitat Map")}`;
    const body = `body=${encodeURI(location)}`;
    const mailTo = `mailto:${mailToAddress}?${subject}&${body}`;
    return mailTo;
  }

  render() {
    const {
      viewAboutMap,
      viewTokenSubmit,
      tokenValidated,
      displayErrMsg,
      tokenString
    } = this.state;
    const { closeLightbox } = this.props;

    return (
      <div className="about-and-submit-feedback">
        {viewAboutMap && !viewTokenSubmit && !tokenValidated ? (
          <div className="light-box-about">
            <h3>Mapping Land Management at MPG Ranch</h3>
            <p>{aboutMapText}</p>
            <GoBack close={closeLightbox}>Get Started</GoBack>
            <p
              className="uppercase cursor-pointer"
              onClick={() => this.viewTokenSubmitForm()}
            >
              send us feedback
            </p>
          </div>
        ) : viewAboutMap && viewTokenSubmit && !tokenValidated ? (
          <div className="light-box-about">
            <form onSubmit={this.handleSubmit}>
              <h3>Please enter your password to provide feedback</h3>
              <input
                ref="tokenInput"
                type="text"
                value={tokenString}
                onChange={this.handleInputChange}
              />
              <button className="button" type="submit">
                Submit
              </button>
              <div>
                {displayErrMsg && (
                  <p
                    className="uppercase"
                    style={{ color: "red", fontWeight: 700 }}
                  >
                    incorrect password
                  </p>
                )}
                <GoBack close={closeLightbox} />
              </div>
            </form>
          </div>
        ) : viewAboutMap && viewTokenSubmit && tokenValidated ? (
          <div className="light-box-about">
            <p>
              {"You may email your feedback to "}
              <a href={this.createMailTo()}>{mailToAddress}</a>
            </p>
            <GoBack close={closeLightbox} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default AboutAndSubmitFeedback;
