import React, { PropTypes, Component } from "react";
import Collapse from "react-collapse";
import cx from "classnames";

import { parseMarkDown } from "../../constants/app.js";

class ManagementInfo extends Component {
  static propTypes = {
    handleArrowClick: PropTypes.func.isRequired,
    detailSection: PropTypes.string.isRequired,
    sectionHeight: PropTypes.number.isRequired
  };

  constructor() {
    super();
    this.makeScrollable = this.makeScrollable.bind(this);
    this.renderListItems = this.renderListItems.bind(this);
  }

  makeScrollable(height, opened) {
    const target = this.refs.mgmtGoalsConstraints;
    if (opened) {
      target.classList.add("scroll");
    } else {
      target.classList.remove("scroll");
    }
  }

  renderListItems(itemType) {
    const { logMgmt } = this.props;
    const noType = itemType.replace(/_/g, " ");
    const nothingLogged = (
      <li>
        <p>{`No management ${noType} logged.`}</p>
      </li>
    );
    if (logMgmt.length) {
      const items = logMgmt.filter(obj => {
        // filters out empty strings in goal[itemType]
        return obj[itemType].length > 0;
      });
      if (items.length) {
        return items.map(obj => {
          return (
            <li
              key={obj.cartodb_id}
              className="markdown-parsed"
              dangerouslySetInnerHTML={{ __html: parseMarkDown(obj[itemType]) }}
            />
          );
        });
      } else {
        return nothingLogged;
      }
    } else {
      return nothingLogged;
    }
  }

  renderPlanReports() {
    const { reports, openLightbox } = this.props;
    const noPlans = (
      <li>
        <p>{"No management plan report logged."}</p>
      </li>
    );

    if (reports.length) {
      const filtered = reports.filter(report => {
        return report.report_type === "plan";
      });

      if (filtered.length) {
        return filtered.map(item => {
          return (
            <li
              key={item.cartodb_id}
              onClick={() => openLightbox({ slides: item })}
            >
              <p className="mgmt-report-link">
                <strong>Plan Report:</strong> {item.report_name}
              </p>
            </li>
          );
        });
      } else {
        return noPlans;
      }
    } else {
      return noPlans;
    }
  }

  render() {
    const { handleArrowClick, detailSection, sectionHeight } = this.props;
    const mgmtOpened = detailSection === "MGMT";

    const titleClassNames = cx({
      "title-and-arrow": true,
      transparent: true,
      opened: mgmtOpened
    });

    return (
      <section className="mgmt-info transparent">
        <div
          className={titleClassNames}
          onClick={() => handleArrowClick("MGMT")}
        >
          <h2 className="title">Management</h2>
        </div>
        <Collapse
          className="details transparent"
          isOpened={mgmtOpened}
          fixedHeight={sectionHeight}
        >
          <div ref="mgmtGoalsConstraints" className="scroll">
            <h3>Goals</h3>
            <ul>{this.renderListItems("goals")}</ul>
            <h3>Constraints</h3>
            <ul>{this.renderListItems("constraints")}</ul>
            <h3>Plans</h3>
            <ul>
              {this.renderListItems("plans")}
              {this.renderPlanReports()}
            </ul>
            <h3>Site History</h3>
            <ul>{this.renderListItems("site_history")}</ul>
          </div>
        </Collapse>
      </section>
    );
  }
}

export default ManagementInfo;
