import { connect } from "react-redux";
import { getSelectedLayer } from "../reducers/index";
import {
  selectMgmtUnit,
  setMgmtUnitZoomCenter
} from "../actions/mgmt_unit_actions";
import { resetCurrentSlideIndex } from "../actions/lightbox_actions";
import { mobileNavChanged } from "../actions/mobile_nav_actions";

import LeafletMapContainer from "../components/LeafletMap/index.jsx";

const mapStateToProps = (state, ownProps) => {
  const { mgmtUnits, map } = state;
  const { geojson, selectedUnit } = mgmtUnits;
  const { terrain } = map;

  return {
    geojson,
    selectedUnit,
    selectedLayer: getSelectedLayer(state),
    displayMobile: ownProps.displayMobile,
    mapOptions: ownProps.mapOptions,
    onMapMoved: ownProps.onMapMoved,
    selectedNav: ownProps.selectedNav,
    terrain
  };
};

export default connect(
  mapStateToProps,
  {
    updateSelectedMgmtUnit: selectMgmtUnit,
    resetCurrentSlideIndex,
    updateMobileNavSelection: mobileNavChanged,
    setMgmtUnitZoomCenter
  }
)(LeafletMapContainer);
