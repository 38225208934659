import { connect } from "react-redux";
import { getSelectedLayer } from "../reducers/index";
import MapLegend from "../components/MapLegend.jsx";

const mapStateToProps = state => ({
  selectedLayer: getSelectedLayer(state),
  layersTabOpen: state.map.layersTabOpen
});

export default connect(mapStateToProps)(MapLegend);
