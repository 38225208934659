import React from "react";

const EnvInfoRow = props => {
  const {
    title1,
    title2,
    measure1,
    measure2,
    subTitle1,
    subTitle2,
    single
  } = props;
  const defaultVal = "n/a";

  const renderColumns = () => (
    <div className="env-row">
      <div className="env-column">
        <h3>
          {title1 || defaultVal}
          {measure1 ? <span className="measure"> {measure1}</span> : null}
        </h3>
        <p>{subTitle1}</p>
      </div>
      <div className="env-column">
        <h3>
          {title2 || defaultVal}
          {measure2 ? <span className="measure"> {measure2}</span> : null}
        </h3>
        <p>{subTitle2}</p>
      </div>
    </div>
  );

  const renderSingleRow = () => (
    <div className="env-row env-row-single">
      <h3>
        {title1 || defaultVal}
        {measure1 ? <span className="measure"> {measure1}</span> : null}
      </h3>
      <p>{subTitle1}</p>
    </div>
  );

  return single ? renderSingleRow() : renderColumns();
};

export default EnvInfoRow;
