import React, { Component } from "react";
import cx from "classnames";

class MapLegend extends Component {
  renderValuesList(arr) {
    return arr.reverse().map(val => {
      return <li key={val}>{val}</li>;
    });
  }

  render() {
    const { selectedLayer, layersTabOpen, displayMobile } = this.props;
    // values at color stops for 2015 NDVI
    const minMaxNDVI = ["- 0.498", "- 0.244", "0.009", "0.262", "0.516"];

    let valuesList = [];

    let moreLessLabel = "";

    const rampClassNames = cx({
      "color-ramp": true,
      prGrn: selectedLayer === "ndvi2015",
      ylOrRd: selectedLayer === "avgSolarIns"
    });

    const mapLegendClassNames = cx(
      "map-legend",
      "ui-container",
      "bottom",
      "left",
      {
        closed: !layersTabOpen
      }
    );

    if (selectedLayer === "avgSolarIns") {
      moreLessLabel = "Radiation";
    } else if (selectedLayer === "ndvi2015") {
      valuesList = this.renderValuesList(minMaxNDVI);
      moreLessLabel = "Green";
    }

    // legend displays when the MapLayersTab is open & viewport size is not mobile OR
    // when MapLayersTab is closed & viewport size is mobile
    return (
      ((selectedLayer === "avgSolarIns" && layersTabOpen && !displayMobile) ||
        (selectedLayer === "avgSolarIns" && !layersTabOpen && displayMobile) ||
        (selectedLayer === "ndvi2015" && layersTabOpen && !displayMobile) ||
        (selectedLayer === "ndvi2015" && !layersTabOpen && displayMobile)) && (
        <div className={mapLegendClassNames}>
          <div className="map-legend-more">
            <p>{`More ${moreLessLabel}`}</p>
          </div>
          <div className={rampClassNames} />
          {!displayMobile && (
            <div className="values">
              <ul>{valuesList}</ul>
            </div>
          )}
          <div className="map-legend-less">
            <p>{`Less ${moreLessLabel}`}</p>
          </div>
        </div>
      )
    );
  }
}

export default MapLegend;
