import {
  SELECT_MGMT_UNIT,
  DESELECT_MGMT_UNIT,
  TOGGLE_MU_DETAIL_SECTION,
  OPEN_MU_LIST,
  CLOSE_MU_LIST,
  SET_MGMT_UNIT_ZOOM_CENTER
} from '../constants/action_names.js';

import { defaultState } from '../reducers/mgmt_units_reducer';

export const selectMgmtUnit = (unitName = null, section = defaultState.detailSection) => ({
  type: SELECT_MGMT_UNIT,
  unitName,
  section
});

export const deselectMgmtUnit = () => ({
  type: DESELECT_MGMT_UNIT,
  unitName: null,
  section: defaultState.detailSection
});

export const toggleMUDetailSection = (section = defaultState.detailSection) => ({
  type: TOGGLE_MU_DETAIL_SECTION,
  section
});

export const openMUList = () => ({
  type: OPEN_MU_LIST
});

export const closeMUList = () => ({
  type: CLOSE_MU_LIST
});

export const setMgmtUnitZoomCenter = (center = null, zoom = null) => ({
  type: SET_MGMT_UNIT_ZOOM_CENTER,
  center: center,
  zoom: zoom
});
