import { MOBILE_NAV } from "../constants/action_names.js";

const reducer = (state = "map", action) => {
  switch (action.type) {
    case MOBILE_NAV:
      return action.mobileNavSelection;
    default:
      //
  }
  return state;
}

export default reducer;
