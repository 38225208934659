import PropTypes from "prop-types";
import React, { Component } from "react";
import * as L from "leaflet";
import throttle from "lodash.throttle";

import { basemapLayers } from "../../constants/config";
import Button from "../Button.jsx";

class LeafletMap extends Component {
  static propTypes = {
    displayMobile: PropTypes.bool,
    onMapMoved: PropTypes.func.isRequired,
    selectedLayer: PropTypes.string,
    selectedNav: PropTypes.bool,
    selectedUnit: PropTypes.string,
    updateSelectedMgmtUnit: PropTypes.func.isRequired,
    updateMobileNavSelection: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.map = null;
    this.mgmtUnitsLayer = null;
    this.tooltip = null;
    this.basemapTileLayers = {};

    // mgmt unit polygon default styling
    this.polygonStyleDefault = {
      color: "#ccc",
      weight: 2,
      fillOpacity: 0,
      opacity: 0.5
    };

    // for mgmt unit polygon mouse over & selection from UI
    this.polygonStyleHighlight = {
      weight: 7,
      color: "orange",
      opacity: 1
    };

    // maximum panable area of the map = ranch boundary buffered by 2500m
    this.maxBounds = [[46.64, -114.06], [46.85, -113.93]];
  }

  componentDidMount() {
    const { geojson } = this.props;
    this.initMap(this.refs.leafletMap);

    // add the mgmt unit overlay
    if (!this.mgmtUnitsLayer && geojson && geojson.features) {
      this.addMgmtUnitPolygons(geojson);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { selectedUnit, selectedLayer } = nextProps;
    // zoom to MU polygon when selected from elsewhere in the app
    if (selectedUnit && selectedUnit !== this.props.selectedUnit) {
      this.zoomToUnit(selectedUnit);
    } else if (this.mgmtUnitsLayer && !selectedUnit) {
      this.clearMgmtUnitPolygonHighlight();
    }
    // toggle tile layers
    if (selectedLayer !== this.props.selectedLayer) {
      this.toggleTileLayers(selectedLayer);
    }
  }

  shouldComponentUpdate() {
    // because this is a wrapper for Leaflet.JS, we don't want React altering
    // this part of the DOM. We want Leaflet to.
    return false;
  }

  initMap(node) {
    const that = this;

    // create the tile layers
    basemapLayers.forEach(layer => {
      this.basemapTileLayers[layer.id] = L.tileLayer(layer.url, {
        opacity: 1,
        zIndex: 0,
        maxZoom: 20,
        bounds: layer.bounds ? layer.bounds : null
      });
    });

    // instantiate Leaflet map
    this.map = L.map(node, {
      center: this.props.mapOptions.center,
      zoom: this.props.mapOptions.zoom,
      layers: [
        this.basemapTileLayers.terrain,
        this.basemapTileLayers[this.props.selectedLayer]
      ],
      maxZoom: 20,
      minZoom: 12,
      zoomControl: false,
      doubleClickZoom: false,
      maxBounds: that.maxBounds
    });

    // update store.map with center & zoom
    this.map.on("moveend", this.props.onMapMoved);

    // on mobile enable click event on popup text
    this.map.on("popupopen", function() {
      if (that.props.displayMobile) {
        const el = document.querySelectorAll(".mu-popup-label")[0];
        if (el) {
          el.addEventListener("click", function(e) {
            that.props.updateSelectedMgmtUnit(el.textContent);
          });
        }
      }
    });
  }

  toggleTileLayers(layerId) {
    switch (layerId) {
      case "terrain":
        Object.keys(this.basemapTileLayers).forEach(layer => {
          if (layer !== "terrain") {
            this.map.removeLayer(this.basemapTileLayers[layer]);
          }
        });
        break;
      default:
        Object.keys(this.basemapTileLayers).forEach(layer => {
          if (
            layer !== "terrain" &&
            this.map.hasLayer(this.basemapTileLayers[layer])
          ) {
            this.map.removeLayer(this.basemapTileLayers[layer]);
          }
          if (!this.map.hasLayer(this.basemapTileLayers[layerId])) {
            this.map.addLayer(this.basemapTileLayers[layerId]);
          }
        });
    }
  }

  // creates the GeoJSON overlay & events for Mgmt Unit Polygons
  addMgmtUnitPolygons(geojson) {
    const that = this;
    let showTooltip = false;

    function positionToolTip(e) {
      if (!showTooltip) return;
      const { clientX, clientY } = e.originalEvent;
      const winWidth = window.innerWidth;
      const ttWidth = that.tooltip.offsetWidth;
      const ttHeight = that.tooltip.offsetHeight;
      let top = 0;
      let left = 0;

      if (clientX > winWidth - ttWidth - 25) {
        left = clientX - ttWidth - 35;
      } else {
        left = clientX + 25;
      }

      if (clientY < ttHeight + 10) {
        top = clientY + 20;
      } else {
        top = clientY - 40;
      }
      that.tooltip.style.cssText = `top: ${top}px; left: ${left}px;`;
    }

    function setToolTipContent(e) {
      const layer = e.target;
      if (!showTooltip) showTooltip = true;
      that.tooltip.innerHTML = `<p>${layer.feature.properties.name}</p>`;
      that.tooltip.style.display = "block";
    }

    function hideToolTip(e) {
      showTooltip = false;
      that.tooltip.style.display = "none";
    }

    function highlightFeature(e) {
      let layer = e.target;
      layer.setStyle(that.polygonStyleHighlight);

      if (!L.Browser.ie && !L.Browser.opera) {
        layer.bringToFront();
      }
    }

    function resetHighlight(e) {
      polygons.resetStyle(e.target);
    }

    function handleFeatureClick(e) {
      const unitName = e.target.feature.properties.name;
      if (unitName === that.props.selectedUnit) return;

      // clicking a polygon has a different action on desktop vs. mobile
      if (that.props.displayMobile) {
        that.zoomToUnit(unitName);
        resetHighlight(e);
        highlightFeature(e);
      } else {
        that.props.updateSelectedMgmtUnit(unitName);
      }
    }

    function updateSelectedMgmtUnit(e) {
      const unitName = e.target.feature.properties.name;
      if (unitName === that.props.selectedUnit) return;
      that.props.updateSelectedMgmtUnit(unitName);
    }

    function onEachFeature(feature, layer) {
      const muName =
        feature && feature.properties ? feature.properties.name : "";
      // disable mouse events on the entire ranch polygon
      if (muName !== "MPG Ranch") {
        layer.on({
          mouseover: function(e) {
            highlightFeature(e);
            setToolTipContent(e);
          },
          mouseout: function(e) {
            resetHighlight(e);
            hideToolTip();
          },
          click: handleFeatureClick,
          dblclick: updateSelectedMgmtUnit
        });
      }

      if (that.props.displayMobile && muName !== "MPG Ranch") {
        // on mobile we display a popup when clicking on the feature
        const popupContent = `<h3 class='mu-popup-label'>${muName}</h3>`;
        layer.bindPopup(popupContent);
      }
    }

    const polygons = L.geoJson(geojson, {
      style: this.polygonStyleDefault,
      onEachFeature
    });

    polygons.addTo(this.map);

    this.map.on("mousemove", throttle(positionToolTip, 50));

    this.mgmtUnitsLayer = polygons;
  }

  clearMgmtUnitPolygonHighlight() {
    this.mgmtUnitsLayer.setStyle(this.polygonStyleDefault);
  }

  getCurMapView() {
    if (!this.map) return;
    let mapView = {};
    mapView.zoom = this.map.getZoom();
    mapView.center = this.map.getCenter();
    return mapView;
  }

  updateMapView(query) {
    this.map.setView([query.center.lat, query.center.lng], query.zoom);
  }

  zoomToUnit(selectedUnit, highlight = true) {
    let unitPolygon;
    let padding = {};

    this.mgmtUnitsLayer.eachLayer(layer => {
      if (layer.feature.properties.name === selectedUnit) {
        unitPolygon = layer;
        if (highlight) layer.setStyle(this.polygonStyleHighlight);
        if (layer.feature.properties.name !== "MPG Ranch") layer.bringToFront();
      } else {
        layer.setStyle(this.polygonStyleDefault);
      }
    });

    if (!unitPolygon) return;
    if (this.props.displayMobile) {
      padding.paddingBottomRight = [0, 0];
    } else {
      padding.paddingBottomRight = [350, 0];
    }
    this.map.fitBounds(unitPolygon.getBounds(), padding);
    this.props.setMgmtUnitZoomCenter(this.map.getCenter(), this.map.getZoom());
  }

  zoomToRanch() {
    // clear the selected MU and zoom to ranch extent
    this.props.updateSelectedMgmtUnit(undefined);
    this.zoomToUnit("MPG Ranch", false);
  }

  render() {
    const { displayMobile, selectedNav } = this.props;

    let style = {
      display: displayMobile && !selectedNav ? "none" : "block"
    };

    return (
      <div className="leaflet-map" style={style}>
        <div className="leaflet-map-btns">
          <Button
            type="button"
            className="btn-zoom-in"
            onClick={() => this.map.zoomIn()}
          />
          <Button
            type="button"
            className="btn-zoom-out"
            onClick={() => this.map.zoomOut()}
          />
          <Button
            type="button"
            className="btn-zoom-ranch"
            onClick={() => this.zoomToRanch()}
          />
        </div>
        <div ref="leafletMap" id="map" />
        <div className="tooltip" ref={i => (this.tooltip = i)} />
      </div>
    );
  }
}

export default LeafletMap;
