import marked from "marked";

// marked is a library for parsing markdown
marked.setOptions({
  sanitize: true,
  gfm: true, // Github flavored Markdown
  smartypants: true,
  breaks: true
});

// mainly for creating hyperlink text, eg: [example](https://example.com)
export function parseMarkDown(string) {
  return marked(string);
}

// default Leaflet map zoom & center
export const initialMapView = {
  zoom: 13,
  center: [46.6987, -114.009]
};

// hydrated state for default page load
const defaultState = {};

export const makeDefaultState = params => {
  const state = { ...defaultState };
  if (params && params.mu) {
    state.mgmtUnits.selectedUnit = params.mu;
  }
  if (params && params.report) {
    state.lightbox.opened = true;
    state.lightbox.data = state.reports.find(row => {
      return row.report_id === params.report;
    });
    state.lightbox.selectedReport = params.report;
    state.mgmtUnits.selectedUnit = state.lightbox.data.slides.report_mu;
  }
  return state;
};

export const parseQueryParams = query => {
  const { zoom, lat, lng, mu, report } = query;
  const parsed = { mu, report };

  if (zoom && lat && lng) {
    parsed.zoom = +zoom;
    parsed.center = {
      lat: +lat,
      lng: +lng
    };
  }

  return parsed;
};

// code credit: https://muffinresearch.co.uk/removing-leading-whitespace-in-es6-template-strings/
// convert multiline es6 template strings to a single line & remove extra whitespace
export function singleLineString(strings, ...values) {
  // Interweave the strings with the
  // substitution vars first.
  let output = "";
  for (let i = 0; i < values.length; i++) {
    output += strings[i] + values[i];
  }
  output += strings[values.length];

  // Split on newlines.
  let lines = output.split(/(?:\r\n|\n|\r)/);

  // Rip out the leading whitespace.
  return lines
    .map(line => {
      return line.replace(/^\s+/gm, "");
    })
    .join(" ")
    .trim();
}

export function computeComponentDimensions(selector) {
  // requires a class name or id
  if (typeof selector !== "string") return false;
  let dimensions = {};
  let element = document.querySelector(selector);
  let elementStyle;
  if (element) {
    elementStyle = window.getComputedStyle(element);
    dimensions.height = +elementStyle.height.replace("px", "");
    dimensions.width = +elementStyle.width.replace("px", "");
  } else {
    return false;
  }
  return dimensions;
}

// creates the photo URL string for public photos stored in MPG's Egnyte account
export function buildPhotoURL(token, size) {
  const { width, height } = size;
  let params = "";
  if (width) params += `&w=${width}`;
  if (height) params += `&h=${height}`;
  const baseurl = "https://mpgcloud.egnyte.com/dd/";
  const queryString = `?thumbNail=1${params}&type=proportional&preview=true`;
  const url = `${baseurl}${token}/${queryString}`;
  return url;
}

// format photo data for react-image-gallery component used in MU Detail & LightBox
export function formatImages(photoData, size) {
  return photoData.map(photo => {
    return {
      original: `${buildPhotoURL(photo.egnyte_photo_public_token, size)}`,
      originalClass: photo.featured_photo ? "featured-slide" : "",
      description:
        size.width < 300 && photo.photo_description
          ? `${photo.photo_description.substring(0, 35)}...`
          : photo.photo_description,
      size,
      section: photo.section
    };
  });
}
