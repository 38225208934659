import {
  TOGGLE_BASEMAP_LAYER,
  MAP_LAYERS_TAB_OPEN
} from '../constants/action_names.js';

export const toggleBasemapLayer = (layer) => ({
  type: TOGGLE_BASEMAP_LAYER,
  layer
});

export const layersTabOpen = () => ({
  type:MAP_LAYERS_TAB_OPEN
});
