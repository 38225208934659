import * as actions from "../constants/action_names.js";

const reducer = (
  state = { opened: false, data: { photos: [], slides: [] }, index: 0 },
  action
) => {
  switch (action.type) {
    case actions.LIGHTBOX_OPENED:
      return {
        ...state,
        opened: true,
        data: action.data,
        about: action.about,
        feedback: action.feedback,
        selectedReport:
          action.data.slides && action.data.slides.report_id
            ? action.data.slides.report_id
            : null
      };

    case actions.LIGHTBOX_CLOSED:
      return {
        ...state,
        opened: false,
        data: { photos: [], slides: [] },
        about: false,
        feedback: false,
        selectedReport: false
      };

    case actions.SET_CURRENT_SLIDE_INDEX:
      return {
        ...state,
        index: action.index
      };

    case actions.RESET_CURRENT_SLIDE_INDEX:
      return {
        ...state,
        index: 0
      };
    default:
      //
  }

  return state;
};

export default reducer;
