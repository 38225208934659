import * as actions from "../constants/action_names.js";

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actions.LIVEMAP_DATA_RESPONSE:
      const { liveCams, soilStations, weatherStations } = action;
      return {
        ...state,
        loading: false,
        liveCams,
        soilStations,
        weatherStations
      };

    case actions.LIVEMAP_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };

    case actions.LIVEMAP_DATA_ERROR_RESPONSE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      //
  }

  return state;
};

export default reducer;
