export const MGMT_UNITS_DATA_REQUEST = "MGMT_UNITS_DATA_REQUEST";
export const MGMT_UNITS_DATA_RESPONSE = "MGMT_UNITS_DATA_RESPONSE";
export const MGMT_UNITS_DATA_ERROR_RESPONSE = "MGMT_UNITS_DATA_ERROR_RESPONSE";

export const MGMT_UNITS_POLYGONS_REQUEST = "MGMT_UNITS_POLYGONS_REQUEST";
export const MGMT_UNITS_POLYGONS_RESPONSE = "MGMT_UNITS_POLYGONS_RESPONSE";
export const MGMT_UNITS_POLYGONS_ERROR_RESPONSE =
  "MGMT_UNITS_POLYGONS_ERROR_RESPONSE";

export const MGMT_UNITS_METADATA_REQUEST = "MGMT_UNITS_METADATA_REQUEST";
export const MGMT_UNITS_METADATA_RESPONSE = "MGMT_UNITS_METADATA_RESPONSE";
export const MGMT_UNITS_METADATA_ERROR_RESPONSE =
  "MGMT_UNITS_METADATA_ERROR_RESPONSE";

export const LIVEMAP_DATA_REQUEST = "LIVEMAP_DATA_REQUEST";
export const LIVEMAP_DATA_RESPONSE = "LIVEMAP_DATA_RESPONSE";
export const LIVEMAP_DATA_ERROR_RESPONSE = "LIVEMAP_DATA_ERROR_RESPONSE";

export const LOG_ACTIONS_DATA_REQUEST = "LOG_ACTIONS_DATA_REQUEST";
export const LOG_ACTIONS_DATA_RESPONSE = "LOG_ACTIONS_DATA_RESPONSE";
export const LOG_ACTIONS_DATA_ERROR_RESPONSE =
  "LOG_ACTIONS_DATA_ERROR_RESPONSE";

export const LOG_MGMT_DATA_REQUEST = "LOG_MGMT_DATA_REQUEST";
export const LOG_MGMT_DATA_RESPONSE = "LOG_MGMT_DATA_RESPONSE";
export const LOG_MGMT_DATA_ERROR_RESPONSE = "LOG_MGMT_DATA_ERROR_RESPONSE";

export const MGMT_UNITS_ENV_PHOTOS_REQUEST = "MGMT_UNITS_ENV_PHOTOS_REQUEST";
export const MGMT_UNITS_ENV_PHOTOS_RESPONSE = "MGMT_UNITS_ENV_PHOTOS_RESPONSE";
export const MGMT_UNITS_ENV_PHOTOS_ERROR_RESPONSE =
  "MGMT_UNITS_ENV_PHOTOS_ERROR_RESPONSE";

export const REPORTS_DATA_REQUEST = "REPORTS_DATA_REQUEST";
export const REPORTS_DATA_RESPONSE = "REPORTS_DATA_RESPONSE";
export const REPORTS_DATA_ERROR = "REPORTS_DATA_ERROR";

export const COMBINE_ACTION_LOGS_REPORTS = "COMBINE_ACTION_LOGS_REPORTS";

export const SELECT_MGMT_UNIT = "SELECT_MGMT_UNIT";
export const DESELECT_MGMT_UNIT = "DESELECT_MGMT_UNIT";
export const TOGGLE_MU_DETAIL_SECTION = "TOGGLE_MU_DETAIL_SECTION";
export const OPEN_MU_LIST = "OPEN_MU_LIST";
export const CLOSE_MU_LIST = "CLOSE_MU_LIST";
export const SET_MGMT_UNIT_ZOOM_CENTER = "SET_MGMT_UNIT_ZOOM_CENTER";

export const TOGGLE_BASEMAP_LAYER = "TOGGLE_BASEMAP_LAYER";
export const MAP_LAYERS_TAB_OPEN = "MAP_LAYERS_TAB_OPEN";

export const LIGHTBOX_OPENED = "LIGHTBOX_OPENED";
export const LIGHTBOX_CLOSED = "LIGHTBOX_CLOSED";
export const SET_CURRENT_SLIDE_INDEX = "SET_CURRENT_SLIDE_INDEX";
export const RESET_CURRENT_SLIDE_INDEX = "RESET_CURRENT_SLIDE_INDEX";

export const MOBILE_NAV = "MOBILE_NAV";
