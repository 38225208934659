import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import queryString from "query-string";

import App from "../views/App.jsx";

import * as asyncActions from "../actions/async_actions.js";
import * as mgmtActions from "../actions/mgmt_unit_actions.js";
import * as mapActions from "../actions/map_actions.js";
import * as lightboxActions from "../actions/lightbox_actions.js";
import combineActionLogsReports from "../actions/combine_action_logs_reports";
import { mobileNavChanged } from "../actions/mobile_nav_actions.js";

import { parseQueryParams } from "../constants/app.js";

const fetchAppData = () => {
  return dispatch =>
    Promise.all([
      dispatch(asyncActions.fetchMgmtUnitsData()),
      dispatch(asyncActions.fetchMgmtUnitsPolygons()),
      dispatch(asyncActions.fetchReportsData()),
      dispatch(asyncActions.fetchLivemapData()),
      dispatch(asyncActions.fetchLogActionsData()),
      dispatch(asyncActions.fetchLogMgmtData()),
      dispatch(asyncActions.fetchEnvPhotos())
    ]);
};

const mapStateToProps = (state, ownProps) => {
  return {
    actionsCombined: state.actionsCombined,
    reports: state.reports.data,
    browser: state.browser,
    map: state.map,
    lightbox: state.lightbox,
    mgmtUnits: state.mgmtUnits,
    recentActions: state.logActionResponses.data,
    logMgmtResponses: state.logMgmtResponses.data,
    routing: state.routing,
    query: parseQueryParams(queryString.parse(window.location.hash.slice(2))),
    mobileNavSelection: state.mobileNavSelection
  };
};

const mapDispatchToProps = dispatch => {
  const actions = {
    ...asyncActions,
    ...mapActions,
    ...mgmtActions,
    ...lightboxActions,
    combineActionLogsReports,
    fetchAppData,
    mobileNavChanged
  };

  return {
    actions: {
      ...bindActionCreators(actions, dispatch)
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
