import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router";
import { bindActionCreators } from "redux";

import { selectMgmtUnit } from "../actions/mgmt_unit_actions";
import ReportSlides from "../components/ReportSlides.jsx";

class Report extends Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.slides !== this.props.slides) {
      this.props.selectMgmtUnit(nextProps.slides.report_mu, "ACTIONS");
    }
  }

  componentDidMount() {
    if (this.props.slides) {
      this.props.selectMgmtUnit(this.props.slides.report_mu, "ACTIONS");
    }
  }

  render() {
    const { router, slides, viewportWidth } = this.props;
    const homeLink = `/${window.location.hash}`;
    return (
      <div className="light-box">
        <div className="black-overlay" onClick={() => router.push(homeLink)} />
        <div className="white-content">
          <Link to={homeLink} className="close" title="close">
            &times;
          </Link>
          {slides ? <ReportSlides {...slides} {...{ viewportWidth }} /> : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ browser, reports, router }, ownProps) => {
  let slides;
  if (reports && reports.data) {
    slides = reports.data.filter(
      report => report.report_id === ownProps.params.id
    )[0];
  }
  return {
    slides,
    viewportWidth: browser.width
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        selectMgmtUnit
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Report));
