import PropTypes from "prop-types";
import React, { Component } from "react";
import Collapse from "react-collapse";
import Select from "react-select";
import cx from "classnames";

import ImageCarousel from "../ImageCarousel.jsx";
import { livemapLiveCamUrl } from "../../constants/config";

class TitleImgCarousel extends Component {
  static propTypes = {
    closeUnitDetail: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    // NOTE: photo URLs & captions are being stored in 3 separate places: mgmtUnits.envPhotoData,
    // logActions, and logMgmt. If there are photos in any of these we need to show them in
    // the photo carousel. Currently there are only 5 available token slots in the
    // Google forms but the number is likely to increase so I've included 10 here.
    this.tokenKeys = [
      "egnyte_photo_public_token",
      "egnyte_photo_public_token_1",
      "egnyte_photo_public_token_2",
      "egnyte_photo_public_token_3",
      "egnyte_photo_public_token_4",
      "egnyte_photo_public_token_5",
      "egnyte_photo_public_token_6",
      "egnyte_photo_public_token_7",
      "egnyte_photo_public_token_8",
      "egnyte_photo_public_token_9",
      "egnyte_photo_public_token_10"
    ];

    this.captionKeys = [
      "photo_caption",
      "photo_caption_1",
      "photo_caption_2",
      "photo_caption_3",
      "photo_caption_4",
      "photo_caption_5",
      "photo_caption_6",
      "photo_caption_7",
      "photo_caption_8",
      "photo_caption_9",
      "photo_caption_10"
    ];
    this.condensePhotoData = this.condensePhotoData.bind(this);
    this.makeScrollable = this.makeScrollable.bind(this);
  }

  makeScrollable(height, opened) {
    const target = this.refs.scrollableSection;
    if (opened) {
      target.classList.add("scroll");
    } else {
      target.classList.remove("scroll");
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.imgCarouselOpened !== this.props.imgCarouselOpened ||
      nextProps.selectedMgmtUnit !== this.props.selectedMgmtUnit
    );
  }

  condensePhotoData(logData, section) {
    let photos = [];

    logData.forEach((log, i) => {
      this.tokenKeys.forEach((tokenKey, j) => {
        let obj = {};
        if (log[tokenKey]) {
          obj.egnyte_photo_public_token = log[tokenKey];
          // TO DO: envPhotos have 'photo_description' instead of 'photo_caption'; should make more consistent in Google Forms
          if (log[this.captionKeys[j]] || log["photo_description"]) {
            obj.photo_description =
              log[this.captionKeys[j]] || log["photo_description"];
          }
          if (log["featured_photo"]) {
            obj.featured_photo = log["featured_photo"];
          }
          // make sure timestamp is added
          obj.timestamp = log.timestamp;
          // track which section the photo belongs to
          obj.section = section;
          photos.push(obj);
        }
      });
    });

    return photos;
  }

  render() {
    const {
      closeUnitDetail,
      envPhotoData,
      logActions,
      logMgmt,
      handleArrowClick,
      name,
      openLightbox,
      lightBoxOpened,
      lightBoxIndex,
      imgCarouselOpened,
      setCurrentSlideIndex,
      resetCurrentSlideIndex,
      detailSection,
      selectedMgmtUnit,
      displayMobile,
      sectionHeight,
      liveCams
    } = this.props;

    const muDetailClassNames = cx({
      "title-and-arrow": true,
      transparent: true,
      "mu-name": true,
      "no-highlight": true
    });

    const titleClassNames = cx({
      "title-and-arrow": true,
      transparent: true,
      opened: imgCarouselOpened
    });

    // Combine photos and get unique
    const byToken = {};
    this.condensePhotoData(envPhotoData, "ENV")
      .concat(this.condensePhotoData(logMgmt, "MGMT"))
      .concat(this.condensePhotoData(logActions, "ACTIONS"))
      .forEach(photo => byToken[photo.egnyte_photo_public_token] = photo);
    const photoDataCombined = Object.values(byToken);

    return (
      <section className="title-and-img-carousel transparent">
        <div className={muDetailClassNames}>
          <span
            className="icon icon-arrow back"
            onClick={() => closeUnitDetail("mgmt_units")}
          />
          <h1 className="title mu-name">{name}</h1>
          <div className="title-icons-left">
            <span
              className="icon icon-feedback"
              onClick={() => openLightbox(undefined, true, true)}
            />
            {displayMobile && (
              <span
                className="icon icon-close-detail"
                onClick={() => closeUnitDetail("map")}
              >
                {"×"}
              </span>
            )}
          </div>
        </div>

        <div
          ref="titleArrow"
          className={titleClassNames}
          onClick={() => handleArrowClick("PHOTOS")}
        >
          <h2 className="title">Media</h2>
        </div>

        <Collapse
          className="img-carousel-container transparent"
          isOpened={imgCarouselOpened}
          fixedHeight={sectionHeight}
        >
          <div
            style={
              displayMobile
                ? { position: "relative", width: "100%", height: "100%" }
                : {}
            }
            ref="scrollableSection"
            className="scroll"
          >
            <ImageCarousel
              photoData={photoDataCombined}
              onSlideChange={setCurrentSlideIndex}
              {...{
                selectedMgmtUnit,
                lightBoxOpened,
                openLightbox,
                lightBoxIndex,
                resetCurrentSlideIndex,
                detailSection
              }}
            />
            <div className="external-links">
              {liveCams && liveCams.length > 0 && (
                <Select
                  placeholder="Live cams"
                  options={liveCams.map(v => ({ label: v.name, value: v }))}
                  onChange={({ value }) => {
                    window.open(
                      livemapLiveCamUrl(value.name, value.mgmt_unit_name),
                      "_blank"
                    );
                  }}
                  searchable={false}
                />
              )}
            </div>
          </div>
        </Collapse>
      </section>
    );
  }
}

export default TitleImgCarousel;
